<template>
<div id="homelanding">
<b-container fluid class="pl-0">
            <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable d-none d-lg-block" >Month Controls:</b-navbar-brand>
        <b-btn @click="goPrev" variant="info" class="d-none d-md-block">Previous Month</b-btn>
        <b-btn @click="goNext" variant="info" class="mx-2 d-none d-md-block">Next Month</b-btn>
         <b-navbar-brand class="bootstrapTable ml-3" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user')">View Users:</b-navbar-brand>
        <div style="width: 300px" class="ml-auto" v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'fireops' || (siteconfig.payroll.staffingFireOpsNonUser && userData.role != 'user')">
        <multiselect v-model="userSelected" 
        :options="deptContactDataReturn" 
        @input="getUserProfile"
        :multiple="false" :close-on-select="true" :clear-on-select="true" 
        :hide-selected="false" :preserve-search="true" placeholder="User" label="lastName" 
        track-by="id">                    
          <template slot="singleLabel" slot-scope="props"><span class="option__desc">{{ props.option.firstName }} {{ props.option.lastName }} | {{ props.option.stationAssignment }}</span></template>
        <template slot="option" slot-scope="props">
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template></multiselect>
        </div>
        <b-btn v-if="siteconfig.payroll.timeCard" variant="success" class="ml-2" @click="showTimeCardModal = !showTimeCardModal">Timecard</b-btn>
      </b-nav-form>
    </b-navbar>
  <b-row>
    <b-col>
<b-card no-body>
  <b-tabs card>
    <b-tab no-body title="Employee Schedule">
<calendar class="calendarClass" :all-events="userRosterProfileDaysWithMods" :daydisplayboo="true" :calendarHeader="calendarHeader" :calendarType="'payroll'" :displayWeekNumber="false" :calendarSmall="true" v-if="showCalendar"></calendar>
    </b-tab>
    <b-tab title="User Payroll Modification">
      <modsUser :userMonthViewModsOrig="userMonthViewModsOrig" :payrollCodesData="payrollCodesData" :userSelected="userSelected" />
      </b-tab>
    <b-tab title="User Banks">
    <bankUser 
    v-if="recoReportsDataSorted"  
    :previousDataReco="recoReportsDataSorted[0]" 
    :userSelected="userSelected ? userSelected : userData" 
    :payrollBanksData="payrollBanksData"
    :payrollAccuralData="payrollAccuralData"
    :payrollCodesData="payrollCodesData"
     />
    </b-tab>
    <b-tab v-if="siteconfig.payroll.flexibleSchedule" title="Timecard Verification">
      <timecardVerification v-if="rosterUserProfiles && resetData && scheduleData && scheduleData.length > 0" 
      :rosterUserProfiles="rosterUserProfiles" 
      :scheduleData="scheduleData"      
      :payrollCodesData="payrollCodesData"
      :payrollHolidaysData="payrollHolidaysData"
      :payrollPermsData="payrollPermsData"
      :holdState="holdState"
      :userSelected="userSelected ? userSelected : userData"  />
      </b-tab>
          <b-tab v-if="siteconfig.payroll.flexibleSchedule" title="Mod Report">
      <employeeModReport v-if="userSelected && payrollCodesData"  
      :payrollCodesData="payrollCodesData"
      :holdState="holdState"
      :userSelected="userSelected ? userSelected : userData"  />
      </b-tab>
    <b-tab v-if="!siteconfig.payroll.flexibleSchedule">
     <h6 slot="title" >Payroll Verification <b-badge v-if="payrollVerification &&  payrollVerificationReviews && checkForUserVerifications(payrollVerification) != 0" variant="danger">{{ checkForUserVerifications(payrollVerification) }}</b-badge></h6>
     <b-table :items="payrollVerification" :fields="['createdAt', 'title', 'status', 'review']">
          <template slot="status" slot-scope="data">
              <span v-if="payrollVerificationReviews" v-html="checkIfUserVerified(data.item.id)"></span>
          </template>
          <template slot="review" slot-scope="data">
            <b-btn variant="info" size="sm" @click="showReviewData(data.item.id)">Review</b-btn>
          </template>
     </b-table>
    </b-tab>
    </b-tabs>
    </b-card>
    </b-col>
  </b-row>
</b-container>
<b-modal v-model="showReviewModal" size="lg" :title="showReviewModalData.title" hide-footer>
    <b-table v-if="showReviewModalData && showReviewModalData.payrollData" :items="showReviewModalData.payrollData.shifts" :fields="['payrollModDatetime', 'payrollModExport', 'payrollModCode', 'payrollModType', 'payrollModHours']">
    <template slot="payrollModDatetime" slot-scope="data">
      {{ getDateTime(data.item.payrollModDatetime) }}
      </template>
      
    </b-table>
    <b-container v-if="(!showReviewModalData.review && (!userSelected || userSelected.id == userData.id)) || userData.role == 'admin'">
      Please review the above information and verify the accuraccy.
      <b-form-checkbox v-model="verifyCorrectBoo" @change="updateBoo('verifyIncorrectBoo')">The information is correct?</b-form-checkbox>
      <b-form-checkbox v-model="verifyIncorrectBoo" @change="updateBoo('verifyCorrectBoo')">The information is incorrect?</b-form-checkbox>
      <b-textarea v-if="verifyIncorrectBoo" v-model="verifyIncorrectComment" placeholder="Please describe the issue" /> 
      <b-btn v-if="verifyIncorrectBoo || verifyCorrectBoo"
            :variant="verifyCorrectBoo ? 'success' : 'danger'"
            @click.once="verifyCorrectBoo ? submitReview(showReviewModalData, true) : submitReview(showReviewModalData, verifyCorrectBoo ? true: false)"
            >{{ verifyCorrectBoo ? 'Submit as Verified' : 'Submit as Incorrect'}}</b-btn>
    </b-container>
    <b-container v-if="showReviewModalData.review">
         <span v-if="showReviewModalData.review.payrollExportReportId" v-html="checkIfUserVerified(showReviewModalData.review.payrollExportReportId)"></span>
    </b-container>
</b-modal>
<b-modal v-if="showTimeCardModal" v-model="showTimeCardModal" title="Employee Timecard" :hide-footer="true">
      <timeCard :employee="userSelected" :schedules="userRosterProfileDays" :openTimeCardObj="openTimeCardObj" />
</b-modal>
</div>
</template>

<script>
import Calendar from '../../calendarFull/Calendar.vue'
import { mapState } from 'vuex'
import { bus } from '../../../main.js'
import axios from 'axios'
import moment from 'moment'
import modsUser from './modsUser.vue'
import bankUser from './bankUser.vue'
import Multiselect from "vue-multiselect";
import timeCard from './timeCard.vue'
import timecardVerification from './timecardVerification.vue'
import employeeModReport from './employeeModReport.vue'
export default {
  props: ['payrollCodesData', 'payrollBanksData', 'payrollAccuralData', 'scheduleData', 'recoReportsData', 'payrollHolidaysData', 'payrollPermsData', 'holdState'],
components: {
  'calendar' : Calendar,
  modsUser,
  bankUser,
  timeCard,
  'multiselect' : Multiselect,
  timecardVerification,
  employeeModReport

},
data() {
    return {
  slide: 0,
  showCalendar: true,
  rosterReload: true,
  userSelected: null,
  showReviewModal: false,
  showReviewModalData: {},
  verifyCorrectBoo: false,
  verifyIncorrectBoo: false,
  openTimeCardObj: null,
  events: [],
  userRosterProfileDaysWithMods: [],
  userMonthViewModsOrig: [],
  userRosterProfileDays: [],
  userMonthViewMods: [],
  payrollVerification: [],
  payrollVerificationReviews: null,
  currentMonth: moment().startOf('month'),
  calendarHeader: 'Employee Schedule',
  currentView: {},
  verifyIncorrectComment: '',
  previousDataReco: '',
  showTimeCardModal: false,
  rosterUserProfiles: '', 
  resetData: true,
}
},
created () {
 // this.getUserProfile()
  bus.$on('CalendarCurrentDisplayMonthEmployee', this.currentDateSelect)
  bus.$on('newPayrollModification', this.getNewModData)
  bus.$on('CalendarCurrentMonthObjectEmployee', this.SetCurrentMonth)
  bus.$on('closeTimeCard', this.closeTimeCard)
  bus.$on('openTimeCard', this.openNewTimeCard)
  bus.$on('goToCalendar', this.updateCalendarUser)
  if(!this.siteconfig.payroll.flexibleSchedule){
  this.loadPayrollVerifications(this.currentView)
  }

 // this.getPayrollVerificationReviews()
},
watch: {
  currentView(newData){
    if(newData){
        this.loadPayrollVerifications(this.currentView)
     //   this.getPayrollVerificationReviews()
    }
  },
  userSelected(newData){
    if(newData){
      this.loadPayrollVerifications(this.currentView)
    //  this.getPayrollVerificationReviews()
    }
  }
},
computed: {
  ...mapState([
    'deptContactData',
    'siteconfig',
    'userData',
    'getSessionData',
  ]),
    deptContactDataReturn(){
      var data = _.cloneDeep(this.deptContactData)
      if(true){ //this.siteconfig.removeSenority){
        data = _.orderBy(data, ['lastName'], ['asc'])
      }
      if(this.siteconfig.payroll.customPerms && this.payrollPermsData){
      var permsData = this.payrollPermsData.find(p => p.payrollUserId == this.userData.id)
        if(permsData && permsData.payrollManageUsers){
          var array = data.filter(p => permsData.payrollManageUsers.indexOf(p.id) >= 0)
          array.push(this.userData)
          return array
        } return data
      } return data

    },
    kellyDayObject(){
   // console.log('KELLY PATTERN')
    if(this.siteconfig.payroll.kellyDayPattern){
    var shifts = this.siteconfig.payroll.shifts
    var kellyPattern = this.siteconfig.payroll.kellyDayPattern
    var build = []
    var kellyArray = []
    for(var k in kellyPattern){
        for(var s in shifts){
        build.push({shift: shifts[s].shift, kelly: this.siteconfig.payroll.kellyDayPattern.indexOf(kellyPattern[k])})
      }
    }
   // console.log(this.currentView.start)
    var today = moment(this.currentView.start, 'YYYY-MM-DD').clone()
    var startDate = today.clone()
   // console.log(today)
    var subDays = 0
   // console.log('START WHILE')
    while (true) {
      var days = moment(startDate).diff(this.siteconfig.payroll.cycleStartDate, 'days') // 21
      var mathhold = (days / 21)
      if(mathhold == Math.floor(mathhold)){
     //   console.log('BREAK')
        break
      }
      startDate = startDate.subtract(1, 'day')
      subDays++;
    } 
     var start = moment(this.currentView.start, 'YYYY-MM-DD').clone().subtract(subDays, 'days')
     //console.log('START')
     //console.log(start)
     var arrayI = 0
    // console.log(start)
     while (true) {
       var obj = _.cloneDeep(build[arrayI])
      // console.log(obj)
       if(!obj){
         arrayI = 0
         obj = _.cloneDeep(build[arrayI])
       //  console.log('FAILED')
       //  console.log(obj)
        // console.log('AFTER OBJ')
       }
       var date = start.clone().format('YYYY-MM-DD')
     //  console.log(date)
       obj['date'] = date
      // console.log(obj)
     //  console.log('PUSH')
       kellyArray.push(obj)
       if(moment(start.clone()).isSameOrAfter(moment(this.currentView.end))){
        // console.log('BREAK WHILE')
         break
       }
       start = start.clone().add(1, 'day')
     //  console.log(start)
     //  console.log('END')
       arrayI++;

     }
     // var patternRun = moment(today.clone()).diff(start, 'days')
    // console.log(patternRun)
    // var index = build[patternRun]
    // console.log(build) 
    // console.log(kellyArray)
     return kellyArray
    } return null
  },
  recoReportsDataSorted(){
          var array = _.orderBy(this.recoReportsData, ['payrollBankRecoDate'], ['desc'])
          if(array[0]){
          return array
          } else {
            return null
          }
        },

},
mounted(){

},
methods: {
  openNewTimeCard(data){
    this.openTimeCardObj = data
    this.showTimeCardModal = true
  },
  updateCalendarUser(id){
    if(id){
      var user = this.deptContactData.find(p => p.id == id)
      if(user){
        this.userSelected = user
        this.getUserProfile()
      } else {
        console.log('Error in user', user)
      }
    } else {
      console.log('Error in id', id)
    }

  },
  closeTimeCard(){
  //  console.log('update close time card')
    document.body.classList.remove('modal-open');
    this.showTimeCardModal = false
    this.openTimeCardObj = null
  },
  updateBoo(item){
    this[item] = false
  },
  showReviewData(id){
    var original = this.payrollVerification.find(p => p.id == id)
    var obj = _.cloneDeep(original)
    var userselect = this.userSelected ? this.userSelected.id : this.userData.id
    if(obj && userselect){
    obj['payrollData'] = obj.payrollData.find(p => p.userid == userselect)
    if(this.payrollVerificationReviews){
    obj['review'] = this.payrollVerificationReviews.find(p => p.exportReviewAuthorId == userselect && p.payrollExportReportId == id)
    } else {
      obj['review'] = null
    }
    this.showReviewModal = true
    this.showReviewModalData = obj
    }

  },
  checkForUserVerifications(data){
    var holdData = _.cloneDeep(data)
    //console.log
    var count = 0
    var userselect = this.userSelected ? this.userSelected.id : this.userData.id
    for(var i in holdData){
       var user = this.payrollVerificationReviews.find(p => p.exportReviewAuthorId == userselect && p.payrollExportReportId == holdData[i].id)
       if(!user){
         count = count + 1
       }
    }
    return count
  },
  checkIfUserVerified(id){
    var userselect = this.userSelected ? this.userSelected.id : this.userData.id
      if(id){
        var user = this.payrollVerificationReviews.find(p => p.exportReviewAuthorId == userselect && p.payrollExportReportId == id)
        if(user){
          if(user && user.requestApproved && user.createdAt){
            return '<span class="text-success">Approved ' + this.getDateTime(user.createdAt) + '</span>'
          } else if (user && user.requestApproved == false && user.createdAt){
            return '<span class="text-danger">Denied ' + this.getDateTime(user.createdAt) + '</span>'
          } else {
            return '<span class="font-weight-bold">Unknown ' + this.getDateTime(user.createdAt) + '</span>'
          }
        } return '<span>No Verification</span>'
      } return '<span>No Verification</span>'
    },
  userProfileBuild(data){
   //   console.log('USER PROFILE BUILD')
   //   console.log(data)
      if(data && this.currentView){
        var filterProfile = data.filter(p => {
        var start = moment(p.rosterAssignmentStart).isBefore(this.currentView.end, 'day')
        var end = p.rosterAssignmentEnd ? moment(p.rosterAssignmentEnd).isAfter(this.currentView.start, "YYYY-MM-DD") : p.rosterAssignmentEnd == null ? true : false
        if(start && end){
          return p
        }
        
        })
        if(filterProfile){
          if(this.siteconfig.payroll.flexibleSchedule){
            this.buildScheduleView(filterProfile)
          } else {
          //  console.log(filterProfile)
            this.userProfileBuildShifts(filterProfile)
          }
        } else {
          return
        }
      }
      /**   var newEvent = {
        id:i+1,
        title: newArr[i].tag,
        description: descriptionTag,
        color: this.getEventTagsVariant(newArr[i].tag),
        date: newArr[i].day }
        */
  },
  SetCurrentMonth(data){
    this.currentMonth = data
  },
  goPrev () {
      let payload = moment(this.currentMonth).subtract(1, 'months').startOf('month');
      var data = {date: payload, view: 'Employee Schedule'}
    //  console.log(data)
      bus.$emit('CHANGE_MONTH_BUS', data);
  },
  goNext () {
      let payload = moment(this.currentMonth).add(1, 'months').startOf('month');
      bus.$emit('CHANGE_MONTH_BUS', {date: payload, view: 'Employee Schedule'});
  },
  enumerateDaysBetweenDates(startDate, endDate){
    var now = _.cloneDeep(startDate)
    var end = endDate
    var dates = []
    while (moment(now).isBefore(end)) {
        dates.push(now);
        now = moment(now, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
    }
    return dates;
    },
    processDaysWithPattern(patterns, dates, logpat, profile){
      var processed = []
      var pattern = _.cloneDeep(patterns)
      for(var i = 0; i < pattern.length; i++){
        var pat = pattern[i]
        if(pat.startTime != null){
          var length = profile.rosterSpotLunchLength ? pat.length - profile.rosterSpotLunchLength : pat.length
          processed.push({date: dates[i], 
                          startTime: pat.startTime, 
                          length: length, 
                          lunch: profile.rosterSpotLunchLength, 
                          auto: profile.rosterSpotAutoLunch,
                          autoLunch: profile.rosterSpotAutoLunch,
                          lunchThreshold: profile.rosterSpotAutoLunchThreshold,
                          lunchLength: profile.rosterSpotLunchLength,
                          scheduledLength: pat.length, 
                          work: true, 
                          pat: logpat})
        } else {
         // processed.push({date: dates[i], pat: logpat})
        }
      }
      return processed
    },
  buildScheduleView(schedules){
    //  console.log('SCHEDULE')
    //  console.log(schedules)
      var holdDatesArray = []
      for(var s in schedules){
      var schObj = schedules[s]
      var schDataObj = this.scheduleData.find(p => p.id == schedules[s].scheduledShift)
    //  console.log(schDataObj)
      var schedule = {
        patternType: schDataObj.patternType,
        schedulePattern: schDataObj.schedulePattern,
        rosterProfile: schedules[s]
      }
      var startDate = schObj.rosterAssignmentStart // comes from their profile
      var start = moment(startDate, 'YYYY-MM-DD')
      var end = this.currentMonth.clone().endOf('month')
    //  console.log(schObj.rosterAssignmentEnd)
    //  console.log(schObj.rosterAssignmentEnd && moment(schObj.rosterAssignmentEnd).isBefore(end))
      if(schObj.rosterAssignmentEnd && moment(schObj.rosterAssignmentEnd).isBefore(end)){
        end =  moment(schObj.rosterAssignmentEnd) // comes from their profile
      }
      var pattern = schedule.patternType
      var momentDiff = pattern == 'weekly' ? 'week' : 'day'
      var momentEnd = pattern == 'weekly' ? 7 : 1
      var startOfMonth = this.currentMonth.clone().startOf('month')
      var startOfMonthClone = _.cloneDeep(startOfMonth)
      var patIterations = startOfMonthClone.clone().diff(start.clone(), 'day')
      var patternArray = schedule.schedulePattern
      var dayTotal = 0
      var patHold = []
      for(var i in patternArray){
        var patTotal = 0
        var length = patternArray[i].interval.length
        var repeat = patternArray[i].repeat
        patTotal = length * repeat
        dayTotal = dayTotal + patTotal
      }
      if(patIterations > (dayTotal * 2)){
              var daysFromStart = 0
              var patIntCalc = _.cloneDeep(patIterations)
              while(Math.floor((patIntCalc/dayTotal)) != (patIntCalc/dayTotal)){
                  daysFromStart++;
                  patIntCalc = patIntCalc - 1
              }
            //  console.log(daysFromStart)
                  var newStart = this.currentMonth.clone().subtract(daysFromStart, 'day')
                  var interations = end.diff(newStart, momentDiff, true)
                  var pattern = 0 // patternStart +1
                  var totalIterations = 0
                  var startHold = newStart //startOfMonth
                  var lastDate = ''
                  var minInterations = 0
                  var maxInterations = 0
                  var movePattern = null
                  var repeatPat = 0
              } else {
                  var interations = end.diff(start, momentDiff, true)
                  var pattern = 0
                  var totalIterations = 0
                  var startHold = start
                  var lastDate = ''
                  var minInterations = 0
                  var maxInterations = 0
                  var movePattern = null
                  var repeatPat = 0
      }
      if(true){
        var patternBuild = []
        var vm = this
        function doTheLoop() {
        for(var i = 0; totalIterations < interations; i++){
            var currentP = patternArray[pattern]
            if(!currentP){
              pattern = 0
              currentP = patternArray[pattern]
            }
            var lastDate = startHold.clone().add(momentEnd, 'days')
            if(end && moment(end).isBefore(lastDate)){
                 lastDate =  moment(end).add(1, 'day')
             }
            if (currentP.repeatType == "PatternInterations") {
                  if(currentP.repeat > repeatPat){
                    repeatPat = repeatPat + 1
                    var daysList = vm.enumerateDaysBetweenDates(startHold.format('YYYY-MM-DD'), lastDate.format('YYYY-MM-DD'))
                    var processDays = vm.processDaysWithPattern(currentP.interval, daysList, pattern, schedule.rosterProfile)
                    patternBuild = patternBuild.concat(processDays)
                    totalIterations = totalIterations + 1
                    startHold = lastDate.clone()
                    if(end && startHold.isSameOrAfter(moment(end))){
                     // console.log('END MADNESS')
                     // console.log(startHold)
                     // console.log(moment(vm.scheduleEndDate))
                       return (false)
                       totalIterations = interations
                    }
                  }  else {
                 //    console.log('Repeat TRIGGERED')
                      repeatPat = 0
                      pattern = pattern + 1
                  }
            } else {
              pattern = pattern + 1
              totalIterations = totalIterations + 1
            }
            if(totalIterations < interations){
              return (true)
            } else {
              return (false)
            }

        }
      }
      while (doTheLoop()) {}
     // console.log(patternBuild)
  }
 var datesHold = patternBuild.filter(p => p.date)
    holdDatesArray = holdDatesArray.concat(datesHold)
  }
    // console.log(holdDatesArray) 
   this.userProfileBuildShiftsScheduled(holdDatesArray)
  },
  userProfileBuildShiftsScheduled(data){
    var events = []
    for(var i in data){
          var index = data.indexOf([i])
          var eventDate = data[i].date + 'T' + data[i].startTime
          var startTime = moment(eventDate).toISOString()
          var endTime = moment(eventDate).add(data[i].scheduledLength, 'hours').toISOString()
          var holdcolor = 'default'
          var obj = {}
          obj['id'] = 'employee-' + index,
          obj['title'] = 'Schedule Assignment'
          obj['description'] = [{id: 'employee-2-' + index, eventDate: eventDate, eventDescription: 'Roster Assignment', eventTitleDescription: 'RS (' + data[i].length + 'hrs)'}]
          obj['color'] = 'text-' + holdcolor
          obj['date'] = data[i].date
          obj['startTime'] = startTime
          obj['endTime'] = endTime
          obj['lunch'] = data[i].lunch
          obj['auto'] = data[i].auto
          obj['autoLunch'] = data[i].autoLunch
          obj['lunchThreshold'] = data[i].lunchThreshold
        events.push(obj)
    }
        this.userRosterProfileDays = events
        return
    },
  userProfileBuildShifts(data){
    var events = []
    for(var i in data){
      if(data[i].scheduledShift != 'Daily'){
      //  console.log('SCHEDULE')
      //  console.log(data[i])
      //  console.log(this.currentView)
      //  console.log(this.kellyDayObject)
        var indexColor = this.siteconfig.payroll.shifts.map(p => p.shift).indexOf(data[i].scheduledShift)
        if(indexColor != -1){
          var holdcolor = this.siteconfig.payroll.shifts[indexColor].bootstrap
        } else {
          var holdcolor = 'default'
        }
        var startDate = moment(data[i].rosterAssignmentStart).isBefore(this.currentView.start, "YYYY-MM-DD") ? this.currentView.start : moment(data[i].rosterAssignmentStart).format("YYYY-MM-DD")
        var shiftStartDate = this.findFirstAvailableShift(startDate, data[i].scheduledShift)
        if(shiftStartDate.isBefore(data[i].scheduledShift)){
          shiftStartDate = shiftStartDate.add(this.siteconfig.payroll.shiftRotation, 'd')
        }
        var endDate = data[i].rosterAssignmentEnd && moment(data[i].rosterAssignmentEnd).isBefore(this.currentView.end, "YYYY-MM-DD") ? data[i].rosterAssignmentEnd : this.currentView.end
        var lengthCalc = moment(endDate).diff(shiftStartDate, 'days')
        var length = Math.floor(lengthCalc/this.siteconfig.payroll.shiftRotation)
        for(var x = 0; x < length; x++){
          var onDutyVerbiage = this.siteconfig.payroll.flexibleSchedule ? "RS" : 'OnDuty'
          var adv = x * this.siteconfig.payroll.shiftRotation
          var date = moment(shiftStartDate).add(adv, 'd').format('YYYY-MM-DD')
          var eventDate = date + 'T' + data[i].rosterStartTime
          var startTime = moment(eventDate).toISOString()
          var endTime = moment(startTime).add(data[i].rosterShiftLength, 'hours').toISOString()
          var obj = {}
          if(this.kellyDayObject){
            var kellyFilter = this.kellyDayObject.find(p => {
                  return moment(p.date).isSame(moment(date), 'day') && p.shift == data[i].scheduledShift && p.kelly == data[i].scheduledKellyCycleNumber
            })
            if(kellyFilter){
        /**    console.log('DATE')
            console.log(date)
            console.log(data[i]) */
            var kellyDay = true
            } else {
             var kellyDay = false
            }

          }
          obj['id'] = data[i] + 1 * x,
          obj['title'] = 'Roster Assignment'
          obj['description'] = [
                {
                 id: data[i] + 1, 
                 eventDate: eventDate, 
                 eventDescription: kellyDay ? 'Kelly Day' : 'Roster Assignment', 
                 eventTitleDescription: kellyDay ? 'Kelly Day (' + data[i].rosterShiftLength + 'hrs)' : 'OnDuty (' + data[i].rosterShiftLength + 'hrs)'
                 }
                ]
          obj['color'] = kellyDay ? 'Yellow' : 'text-' + holdcolor
          obj['date'] = date
          obj['startTime'] = startTime
          obj['endTime'] = endTime
        events.push(obj)
        if(x == length - 1){
          var onDutyVerbiage = this.siteconfig.payroll.flexibleSchedule ? "RS" : 'OnDuty'
          var holdCheck = moment(endDate).diff(endTime, 'days')
          if(holdCheck >= this.siteconfig.payroll.shiftRotation){
          var adv = (x + 1) * this.siteconfig.payroll.shiftRotation
          var date = moment(shiftStartDate).add(adv, 'd').format('YYYY-MM-DD')
          var eventDate = date + 'T' + data[i].rosterStartTime
          var startTime = moment(eventDate).toISOString()
          var endTime = moment(startTime).add(data[i].rosterShiftLength, 'hours').toISOString()
          var newobj = {}
           if(this.kellyDayObject){
            var kellyFilter = this.kellyDayObject.find(p => {
                  return moment(p.date).isSame(moment(date), 'day') && p.shift == data[i].scheduledShift && p.kelly == data[i].scheduledKellyCycleNumber
            })
            if(kellyFilter){
           /** console.log('DATE')
            console.log(date)
            console.log(data[i]) */
            var kellyDay = true
            } else {
             var kellyDay = false
            }

          }
          newobj['id'] = data[i] + 2,
          newobj['title'] = 'Roster Assignment'
          newobj['description'] = [
                {
                 id: data[i] + 1, 
                 eventDate: eventDate, 
                 eventDescription: kellyDay ? 'Kelly Day' : 'Roster Assignment', 
                 eventTitleDescription: kellyDay ? 'Kelly Day (' + data[i].rosterShiftLength + 'hrs)' : 'OnDuty (' + data[i].rosterShiftLength + 'hrs)'
                 }
                ]
         // newobj['description'] = [{id: data[i] + 1, eventDate: eventDate, eventDescription: 'Roster Assignment', eventTitleDescription: 'OnDuty (' + data[i].rosterShiftLength + 'hrs)'}]
          newobj['color'] = kellyDay ? 'Yellow' : 'text-' + holdcolor
          newobj['date'] = date
          newobj['startTime'] = startTime
          newobj['endTime'] = endTime
          events.push(newobj)        
          }
        }
        }
        } else if (data[i].scheduledShift == 'Daily') {
             var indexColor = this.siteconfig.payroll.shifts.map(p => p.shift).indexOf(data[i].scheduledShift)
        if(indexColor != -1){
          var holdcolor = this.siteconfig.payroll.shifts[indexColor].bootstrap
        } else {
          var holdcolor = 'default'
        }
        var startDate = moment(data[i].rosterAssignmentStart).isBefore(this.currentView.start) ? this.currentView.start : moment(data[i].rosterAssignmentStart).format("YYYY-MM-DD")
        var endDate = data[i].rosterAssignmentEnd && moment(data[i].rosterAssignmentEnd).isBefore(this.currentView.end) ? data[i].rosterAssignmentEnd : this.currentView.end
        var days = data[i].scheduledWorkDays
        var length = moment(endDate, 'YYYY-MM-DD').diff(startDate, 'days')
          var onDutyVerbiage = this.siteconfig.payroll.flexibleSchedule ? "RS" : 'OnDuty'
          for(var x = 0; x < length; x++){
          var date = moment(startDate).add(x, 'd').format('YYYY-MM-DD')
          var testDay = days.indexOf(moment(date, 'YYYY-MM-DD').format('ddd'))
          if(testDay != -1){
          var eventDate = date + 'T' + data[i].rosterStartTime
          var startTime = moment(eventDate).toISOString()
          var endTime = moment(startTime).add(data[i].rosterShiftLength, 'hours').toISOString()
          var obj = {}
          obj['id'] = data[i] + 1 * x,
          obj['title'] = 'Roster Assignment'
          obj['description'] = [{id: data[i] + 1, eventDate: eventDate, eventDescription: 'Roster Assignment', eventTitleDescription: onDutyVerbiage + '(' + data[i].rosterShiftLength + 'hrs)'}]
          obj['color'] = 'text-' + holdcolor
          obj['date'] = date
          obj['startTime'] = startTime
          obj['endTime'] = endTime
        events.push(obj)
          }
          }
       }
    }
    this.userRosterProfileDays = events
  },
  updateUserEventList(){
    var array = _.cloneDeep(this.userRosterProfileDays)
    this.userMonthViewModsOrig = this.userMonthViewMods
    var unassigned = this.userMonthViewMods.filter(p => {
      return p //.payrollModApproved //!= false
    })
    var mods = unassigned.map(item => item = this.setUpModCalendar(item))
    for(var i in mods){

      array.push(mods[i])
    }
    if(this.siteconfig.payroll.holidayTable && this.payrollHolidaysData){
     array = this.checkForHolidays(array)
    }
    this.userRosterProfileDaysWithMods = array
  },
  checkForHolidays(data){
    var array = data
    // console.log(array)
    var holidayTest = []
    for(var d in data){
          var holiday = this.payrollHolidaysData.find(p => {
          var userRank = this.userSelected ? this.userSelected.deptRank : this.userData.deptRank
          return moment(p.holidayDate).isSame(data[d].date, 'day') && p.holidayPaytype.indexOf(userRank) >= 0
        })
        var payrollCode = this.payrollCodesData.find(p => p.payrollCodeName == 'Holiday')
        if(holiday && payrollCode && holidayTest.indexOf(data[d].date) == -1){
        holidayTest.push(data[d].date)
        var startTime = data[d].startTime
        var endTime = moment(data[d].startTime).add(holiday.holidayHours, 'hours').toISOString()
        var userId = this.userSelected ? this.userSelected.id : this.userData.id
        var newObj = {
                    id: 'holiday-' + holiday.holidayName.replace(/\s+/g,'-') + userId,
                    payrollModType: payrollCode.payrollCodeCategory,
                    payrollModCode: payrollCode.id,
                    payrollModTargetUserId: this.userSelected ? this.userSelected.id : this.userData.id,
                    payrollModDatetime: startTime,
                    payrollModHours: holiday.holidayHours,
                    payrollModAuthorId: "system",
                    payrollModApprovalUserId: "system",
                    payrollModApproved: true,
                    payrollModNote: holiday.holidayName,
                    startTime: startTime,
                    endTime: endTime,
                    systemCreated: true
        }
        var modObj = this.setUpModCalendar(newObj)
       // console.log(modObj)
        array.push(modObj)
        }
    }
    return array
  },
  setUpModCalendar(item){
          var holdcolor = 'default'
          
          var obj = {}
          var user = this.userSelected ? this.userSelected.id : this.userData.id
          if(item.payrollModExchangeUserId == user){
            var payrollCode = this.payrollCodesData.find(p => p.payrollCode == 'EXON')
            var payrollCodeFind = payrollCode.payrollCode
            var modifier = ''
            if(item.payrollModExchangeApproved && item.payrollModApproved){
              modifier = 'Approved'
            } else if(item.payrollModExchangeApproved && !item.payrollModApproved) {
              modifier = 'Awaiting Approval'
            } else if(!item.payrollModExchangeApproved && !item.payrollModApproved) {
              modifier = 'Awaiting User Approval'
            } else {
              modifier = ''
            }
          } else if (item.payrollModExchangeUserId){
             var payrollCode = this.payrollCodesData.find(p => p.id == item.payrollModCode)
            var payrollCodeFind = payrollCode.payrollCode
            var modifier = ''
            if(item.payrollModExchangeApproved && item.payrollModApproved){
              modifier = 'Approved'
            } else if(item.payrollModExchangeApproved && !item.payrollModApproved) {
              modifier = 'Awaiting Approval'
            } else if(!item.payrollModExchangeApproved && !item.payrollModApproved) {
              modifier = 'Awaiting User Approval'
            } else {
              modifier = ''
            }
          }
          else {
            var payrollCode = this.payrollCodesData.find(p => p.id == item.payrollModCode)
          //  console.log('PAYROLL CODE')
          //  console.log(payrollCode)
            var payrollCodeFind = payrollCode.payrollCode
            var modifier = item.payrollModApproved && item.payrollModType != 'overtime availability' ? 'Approved' : 
                           item.payrollModApproved && item.payrollModType == 'overtime availability' ? 'Available' : 
                           item.payrollModApproved == null ? 'Awaiting Approval' : 'Denied'
          }
          obj['id'] = item.id,
          obj['title'] = item.payrollModType
          obj['description'] = [{eventDate: item.payrollModDatetime, 
                                 id: item.id,
                                 eventDescription: item.payrollModType, 
                                 eventTitleDescription: payrollCodeFind + ' (' + item.payrollModHours + 'hrs) ',
                                 currentStatus: modifier}]
          obj['color'] = payrollCode.payrollCodeColor
          obj['date'] = moment(item.payrollModDatetime).format('YYYY-MM-DD')
          obj['startTime'] = item.payrollModDatetime
          obj['endTime'] = moment(item.payrollModDatetime).add(item.payrollModHours, 'hours').toISOString()
          obj['payrollMod'] = item
          return obj
  },
  findFirstAvailableShift(date, profileshift){
              var payroll = this.siteconfig.payroll
              var indexOfDate = payroll.shifts.map(p => p.shift).indexOf(profileshift)
              var selectedVarDate = moment(date, "YYYY-MM-DD");
              var presetVarDate = moment(payroll.cycleStartDate, "YYYY-MM-DD");
              var dateDiffCal = selectedVarDate.diff(presetVarDate, "days");
              var dateDiffCalNumber = 100*(dateDiffCal/payroll.shiftRotation);
              var dateTenDigit = dateDiffCalNumber % 10;
              var roundedDated = Math.floor(dateTenDigit/payroll.shiftRotation)
              var advance = indexOfDate - roundedDated
              return selectedVarDate.add(advance, 'd')
  },
  currentDateSelect(data){
    if(this.currentView.start != data.start || this.currentView.end != data.end){
  //    console.log(data.start + ' ' + data.end)
     this.currentView = data
     this.getUserProfile()
    }
  },
  getNewModData(){ 
    this.getUserProfile()
   // this.getPayrollModByDateByUser()
  },
    getUserProfile(){
      var user = this.userSelected ? this.userSelected.id : this.userData.id
      if(this.userSelected){
        this.$emit('userSelected', this.userSelected)
      }
        axios({
        method: 'get',
        url: '/api/v1/staffing/rosterprofile/user/' + user,
        headers: {
           'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

        }
       }).then( response => {
        this.rosterUserProfiles = response.data
        this.resetData = false
        this.$nextTick(function(){
          this.resetData = true
        })
        this.userProfileBuild(response.data)
        this.getPayrollModByDateByUser()
      })
    },
    getPayrollModByDateByUser(){
    //  this.rosterReload = false
      var user = this.userSelected ? this.userSelected.id : this.userData.id
      var start = moment(this.currentView.start, "YYYY-MM-DD").toISOString()
      var end = moment(this.currentView.end, "YYYY-MM-DD").toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/usermonthview/' + start + '/' + end + '/' + user,
     headers: {
            'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

     }
   }).then( response => {
    this.userMonthViewMods = response.data
    this.getPayrollModExchange()
    // this.rosterReload = true
   })

    },
    getDateTime(date){
      if(date){
        return moment(date).format('MM/DD/YY')
      } return 'UNKNOWN'
    },
  getPayrollModExchange(){
    //  this.rosterReload = false
      var user = this.userSelected ? this.userSelected.id : this.userData.id
      var start = moment(this.currentView.start, "YYYY-MM-DD").toISOString()
      var end = moment(this.currentView.end, "YYYY-MM-DD").toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollmod/usermonthviewswap/' + start + '/' + end + '/' + user,
     headers: {
           'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

     }
   }).then( response => {
     var array = response.data
      var test = this.userMonthViewMods.map(p => p.id)
     for(var i in array){
       if(test.indexOf(array[i].id) == -1 ){
       this.userMonthViewMods.push(array[i])
       }
     }
     this.updateUserEventList()
    // this.rosterReload = true
   })

    },
    loadPayrollVerifications(dates){
      if(this.currentView.start && this.currentView.end){
      if(this.userSelected){
        var user = this.userSelected.id
      } else {
        var user = this.userData.id
        this.userSelected = this.userData
      }
    //  var user = this.userSelected ? this.userSelected.id : this.userData.id
      var start = moment(this.currentView.start, "YYYY-MM-DD").subtract(1, 'month').toISOString()
      var end = moment(this.currentView.end, "YYYY-MM-DD").toISOString()
      axios({
     method: 'get',
     url: '/api/v1/staffing/payrollexport/user/' + start + '/' + end + '/' + user,
     headers: {
           'Authorization': this.getSessionData,
        'Content-Type' : 'application/json',

     }
   }).then( response => {
      var array = response.data
      if(array && array.length > 0){
      this.payrollVerification = _.orderBy(array, ['createdAt'], ['desc'])
      this.getPayrollVerificationReviews()
      }
   })
      }

  },
  submitReview(data, boo){
      var report = data
      var payroll = {}
      var user = this.userData
      if(!boo && this.verifyIncorrectComment){
        payroll['payrollExportReviewComment'] = this.verifyIncorrectComment
      }
      payroll['exportReviewAuthor'] = user.firstName + ' ' + user.lastName
      payroll['exportReviewAuthorId'] = user.id
      payroll['requestApproved'] = boo
      payroll['payrollData'] = report.payrollData && report.payrollData.shifts ? report.payrollData.shifts : []
      payroll['payrollExportReportId'] = report.id
      payroll['title'] = report.title
      this.showReviewModal = false
      this.showReviewModalData = {}
      axios({
        method: 'post',
        url: '/api/v1/staffing/payrollexportreview',
        data: payroll,
        headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
      }).then( response => {
        // this.loadPayrollVerifications(this.currentView)
        this.getPayrollVerificationReviews()
      })
    },
  getPayrollVerificationReviews(){
    this.payrollVerificationReviews = null
      var user = this.userSelected ? this.userSelected.id : this.userData.id
      axios({
          method: 'get',
          url: '/api/v1/staffing/payrollexportreview/user/' + user,
          headers: {
              'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
          }
        }).then(response => {
          this.payrollVerificationReviews = response.data
      })
  },


},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
</style>
<style scoped>
.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
