<template>
  <div class="hello">
     <div class="headerSpan">{{ pageSource == 'forms' ? 'Digital Forms' : pageSource == 'files' ? 'Files ' : 'Documents '}}  <span v-if="showAdmin">Admin</span>
     <span class="floatRight" v-if="pageSource == 'docs'">
       <b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief'" size="sm" @click="showAdmin = !showAdmin" :variant="checkState">Admin</b-btn>
     </span>
     <span class="floatRight" v-if="pageSource == 'forms'">
       <b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'trainingops'" size="sm" @click="showAdmin = !showAdmin, pageSource = 'forms'" :variant="checkState">Admin</b-btn>
     </span>
    <span class="floatRight" v-if="pageSource == 'files'">
       <b-btn v-if="userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'trainingops'" size="sm" @click="showAdmin = !showAdmin, pageSource = 'files'" :variant="checkState">Admin</b-btn>
     </span></div>
      <div v-if="showAdmin">
        <documentadmin v-if="pageSource == 'docs'" />
            <digitalForms v-if="pageSource == 'forms'"  :showAdmin='true' />
            <digitalFiles v-if="pageSource == 'files'" :showAdmin='true' />
      </div>
      <div v-if="!showAdmin">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Documents" @click="pageSource = 'docs'"  class="px-1 px-md-4">
          <b-navbar type="dark" variant="dark">
      <b-nav-form>
        <b-navbar-brand class="bootstrapTable">Document Search</b-navbar-brand>
        <b-form-input class="bootstrapTable mr-sm-2" type="text"  v-model="searchData" placeholder="Search"></b-form-input>
      </b-nav-form>
          </b-navbar>
    <b-card v-if="showModal">
      <b-btn variant="primary" @click="showModal = false, iframePdf = ''">Back To Forms</b-btn>
      <b-btn variant="info" :href="iframePdf" target="_blank">Open in New Tab (mobile)</b-btn>
      <b-embed v-show="desktopView" :src="iframePdf" />
      </b-card>
    <b-card v-if="searchData != ''" title="Document Search Results">
         <b-pagination
      v-model="currentPage"
      :total-rows="filterdocumentsApiSearch.length"
      :per-page="10"
      align="center"
      aria-controls="my-table"
    ></b-pagination>
     <b-table stacked="md"  hover striped :fields="fields" :items="filterdocumentsApiSearch" :current-page="currentPage" :per-page="10">
        <template slot="File" slot-scope="data" v-if="dataReset">
          <b-btn class="m-0" size="sm" variant="link" v-if="data.item.documentFile" @click="viewFileIframe(data.item.documentFile)">view</b-btn>
          <b-btn class="m-0" size="sm" variant="link" v-if="data.item.documentFile" >copy link</b-btn>
        </template>
      </b-table>
      </b-card>
    <div class="mobileSelect">
    <b-card no-body v-show="searchData == ''">
      <b-form-select :options="documentCats" v-model="mobileSelectParValue" @change="mobileSelectSubValue = null" text-field="documenttypeName" >
      <template>
        <option value="null" disabled>-- Please select a Category --</option>
      </template>
      </b-form-select>
      <b-form-select :disabled="!mobileSelectParValue" :options="documentSub(mobileSelectParValue)" v-model="mobileSelectSubValue" text-field="documenttypeName"  place-holder="Select Sub-Category">
        <template>
        <option value="null" disabled>-- Please select a Sub-Category --</option>
      </template>
      </b-form-select>
     <b-table stacked="md"  v-if="mobileSelectParValue && mobileSelectSubValue" hover striped :fields="fields" :items="filterdocumentsApi(mobileSelectParValue, mobileSelectSubValue)">
                    <template slot="File" slot-scope="data" v-if="dataReset">
                      <b-btn class="m-0" size="sm" variant="link" v-if="data.item.documentFile" @click="viewFileIframe(data.item.documentFile)">view</b-btn>
                      <b-btn v-show="userData.role == 'trainingops' || userData.role == 'admin' || userData.role == 'adminchief'" class="m-0" size="sm" variant="link" v-if="data.item.documentFile" @click="copyToClipboard(data.item.id)">copy link</b-btn>
                    </template>
      </b-table>
      <b-tabs ref="tabs" pills variant="outline-info" v-if="false">
          <b-tab v-if="documentTags.length > 0" v-for="cat in documentCats" title-link-class="bg-info text-light mt-1" :title="cat.documenttypeName" :key="cat.id" @click="currentTab = cat.id">
              <b-tabs pills class="mt-1" v-if="currentTab == cat.id">
                <b-tab v-for="catSub in documentSub(cat.documenttypeName)"  :title="catSub.documenttypeName" :key="catSub.id">
                  <b-table stacked="md"  hover striped :fields="fields" :items="filterdocumentsApi(cat, catSub)">
                    <template slot="File" slot-scope="data" v-if="dataReset">
                      <b-btn class="m-0" size="sm" variant="link" v-if="data.item.documentFile" @click="viewFileIframe(data.item.documentFile)">view</b-btn>
                      <b-btn v-show="userData.role == 'trainingops' || userData.role == 'admin' || userData.role == 'adminchief'" class="m-0" size="sm" variant="link" v-if="data.item.documentFile" @click="copyToClipboard(data.item.id)">copy link</b-btn>
                    </template>
                  </b-table>
                </b-tab>
              </b-tabs>
          </b-tab>
      </b-tabs>
  </b-card>
</div>    
    <div class="deskSelect">
    <b-card no-body v-if="searchData == ''">
      <b-tabs ref="tabs" card >
          <b-tab v-if="documentTags.length > 0" v-for="cat in documentCats" :title="cat.documenttypeName" :key="cat.id">
              <b-tabs pills class="mt-1">
                <b-tab class="mt-1" v-for="catSub in documentSub(cat.documenttypeName)" :title="catSub.documenttypeName" :key="catSub.id" v-if="cat.documenttypeName">
                  <b-table hover striped :fields="fields" :items="filterdocumentsApiDesk(cat, catSub)">
                    <template slot="updatedAt" slot-scope="data">
                      {{ getFormatedDate(data.item.updatedAt) }}
                    </template>
                    <template slot="File" slot-scope="data" v-if="dataReset">
                      <b-btn class="m-0" size="sm" variant="link" v-if="data.item.documentFile" @click="viewFileIframe(data.item.documentFile)">view</b-btn>
                      <b-btn class="m-0" size="sm" variant="link" v-if="data.item.documentFile && userData.role == 'admin'" @click="downloadPdf(data.item)">download</b-btn>
                      <b-btn v-show="userData.role == 'trainingops' || userData.role == 'admin' || userData.role == 'adminchief'" class="m-0" size="sm" variant="link" v-if="data.item.documentFile" @click="copyToClipboard(data.item.id)">copy link</b-btn>
                    </template>
                  </b-table>
                </b-tab>
              </b-tabs>
          </b-tab>
      </b-tabs>
  </b-card>
</div>
  </b-tab>
  <b-tab title="Digital Forms" @click="pageSource = 'forms'" :active="pageSource == 'forms'"  class="px-1 px-md-4">
    <digitalForms />
  </b-tab>
    <b-tab title="Files" @click="pageSource = 'files'" :active="pageSource == 'files'"  class="px-1 px-md-4">
    <digitalFiles />
  </b-tab>
  </b-tabs>
  </b-card>
  </div>
  </div>

</template>

<script>

import { mapState } from 'vuex'
import moment from 'moment'
import axios from 'axios'
import DocumentAdmin from '../admin/adminDocuments.vue'
import digitalForms from './digitalForms.vue'
import digitalFiles from './digitalFiles.vue'
export default {
  name: 'hello',
  props:[],
  components: {
    'documentadmin' : DocumentAdmin,
    digitalForms,
    digitalFiles
  },
  data () {
    return {
      uploadedJson: [

      ],
      mobileSelectParValue: null,
      mobileSelectSubValue: null,
      fields: [
      { key: 'documentId', label: 'ID', sortable: true},
      { key: 'documentName', label: 'Description'},
      { key: 'updatedAt', label: 'Uploaded/Updated' },
      'File',
    /*  'Operations', */
    ],
      dataReset: true,
      pageSource: 'docs',
      showAdmin: false,
      desktopView: true,
      searchData: '',
      catname: '',
      showModal: false,
      subcatname: '',
      newDataUUID: '',
      currentPage: 1,
      currentTab: '',
      imageFileName: '',
      imageFileFID: '',
      newImageFileData: '',
      imageResponseData: '',
      fileName: '',
      photoFileUpload: '',
      postNid: '',
      digitalFormActive: false,
      iframePdf: '',
      page: 1,

    }
  },
  created(){
     this.pullDocuments()

  },
  mounted(){
         var data = this.$route.query
     if(!_.isEmpty(data)){
       if(data.document){
       this.getDocumentFile(data)
       } else if(data.digitalform) {
        // console.log('DIGITAL FORM')
         this.pageSource = null
         this.$nextTick(function(){
           this.pageSource = 'forms'
         })
       }
     }

  },
  computed: {
      ...mapState([
        'apparatusData',
        'documentTags',
        'documentsApi',
        'getSessionData',
        'userData'
      ]),
       checkState(){
      if(this.showAdmin == true){
      return 'light'
       } else {
      return 'outline-light'
     }
    },
      filterdocumentsApiSearch(){
          var array = this.documentsApi.filter( (p) => {
            return p.documentName.toLowerCase().match(this.searchData.toLowerCase()) ||
                  p.documentId.toLowerCase().match(this.searchData.toLowerCase())
          })
          return array
      },
      documentCats(){
        if(this.documentTags.length > 0){
        var newArray = [... new Set(this.documentTags.filter(p => p.documenttypeParent == ''))]
        var hold = _.orderBy(newArray, ['documenttypeName'], ['asc'])
        hold.push({documenttypeName: 'Recent Updates'})
        return hold
        } else {
          return
        }
      },
  },
  watch: {
    documentsApi(newData){
      var data = this.$route.query
     if(!_.isEmpty(data)){
       this.getDocumentFile(data)
     }
    },
  
},
  methods: {
    getDocumentFile(newData){
    if(newData.document){
    if(this.documentsApi.length > 0){
        var document = this.documentsApi.filter(p => {
      return p.id.match(newData.document)
        
    })
  this.viewFile(document[0].documentFile)
    } else if (newData.file){
      this.viewFile(newData.file)
    }
  }
  },
  getFormatedDate(data){
    if(data){
      return moment(data).format('MM/DD/YYYY HH:mm')
    } return ''
  },
  copyToClipboard(data){
      var location = document.URL.split("?")
      document.execCommand("delete")
       var ourLocation = location[0] + '?document=' + data
       var dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.setAttribute("id", "dummy_id");
      document.getElementById("dummy_id").value=ourLocation;  
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      var ourLocation = ''
    },
    viewFile(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
               var a = document.createElement('a')
                    window.open(response.data.signedRequest, '_blank')
       /*        a.setAttribute("href", response.data.signedRequest)
               a.setAttribute("target", "_blank")
               a.dispatchEvent(new MouseEvent("click", {'view': window, 'bubbles': true, 'cancelable': true})) */
            }) 
       },
    viewFileIframe(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              this.iframePdf = response.data.signedRequest
              this.showModal = true
               window.scrollTo(0,0);

       /*        a.setAttribute("href", response.data.signedRequest)
               a.setAttribute("target", "_blank")
               a.dispatchEvent(new MouseEvent("click", {'view': window, 'bubbles': true, 'cancelable': true})) */
            }) 
       },
       getFileDownload(response, newFileName){
        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));

        // Create a link element
        const link = document.createElement('a');
        link.href = url;

        // Set the download attribute with a filename
        link.setAttribute('download',  newFileName + '.pdf');

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
       },
       getDataFromServer(cert, name){
          axios({
            method: 'Get',
            url: cert,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
          }).then(response => {
           // this.getFileDownload(response.data, name)
            //.then(function(response) {
                let blob = new Blob([response.data], { type:   'application/pdf' } );
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = name + '.pdf';
                link.click();

          })
       },
       downloadPdf(fileName) {
            
            var holdName = fileName.documentId.replace(/[^a-zA-Z0-9 ]/g, '') + ' ' + fileName.documentName.replace(/[^a-zA-Z0-9 ]/g, '') + ' ' + moment(fileName.updatedAt).format('YYYY-MM-DD')
              var vm = this
              var first = fileName.documentFile.charAt(0)
              if(first != '/'){
                var newFileName = '/' + fileName.documentFile
              } else {
                var newFileName = fileName.documentFile
              }
               axios({
            method: 'get', 
            url: '/api/v1/upload' + newFileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
             this.getDataFromServer(response.data.signedRequest, holdName)

       /*        a.setAttribute("href", response.data.signedRequest)
               a.setAttribute("target", "_blank")
               a.dispatchEvent(new MouseEvent("click", {'view': window, 'bubbles': true, 'cancelable': true})) */
            }) 
    },
    documentSub(data){
      if(data == 'Recent Updates'){
        return [{documenttypeName: 'Newest'}]
      }
      var returnData = this.documentTags.filter(p => {
        return p.documenttypeParent.match(data)
      })
      if(returnData[0]){
      if(Number.isInteger(parseInt(returnData[0].documenttypeName))){
        var order = 'desc'
      } else {
        var order = 'asc'
      }
      return _.orderBy(returnData, ['documenttypeName'], [order])
      } else {
        return false
      }
    },
    documentSubSelect(data){
      var returnData = this.documentTags.filter(p => {
        return p.documenttypeParent.match(data)
      })
      return returnData
    },
    filterdocumentsApiDesk(cat, catsub){
      if(cat && cat.documenttypeName == 'Recent Updates' && catsub && catsub.documenttypeName == 'Newest'){
        return _.orderBy(this.documentsApi, ['updatedAt'], ['desc']).splice(0 , 50)
      }
      if(cat && cat.documenttypeName &&  catsub && catsub.documenttypeName){
      var returnedValue = this.documentsApi.filter((p) => {
        return p.documentTag.match(catsub.documenttypeName) && p.documentTagParent.match(catsub.documenttypeParent)
      })
      }  return _.orderBy(returnedValue, ['documentId'], ['asc'])
    },
    filterdocumentsApi(cat, catsub){
       if(cat == 'Recent Updates' && catsub == 'Newest'){
        return _.orderBy(this.documentsApi, ['updatedAt'], ['desc']).splice(0 , 50)
      }
      if(cat && catsub){
      var returnedValue = this.documentsApi.filter((p) => {
        return p.documentTag.match(catsub) && p.documentTagParent.match(cat)
      })
      } return _.orderBy(returnedValue, ['documentId'], ['asc'])
    },

    pullDocuments(){
      var headers =  { headers: {
               'Authorization': this.getSessionData,
             'Content-Type' : 'application/json',
           }}
     this.$store.dispatch('LOAD_DOCUMENTS_API_EXP', headers)
    },
  
    getDocTag(data){
      var documents = this.documentTags.filter(p => {
        return p.name_1.match("Directives")
      })
      var matchData = documents.filter(p => {
        return p.name.match(data)})
      return JSON.parse(matchData[0].id)
    },

 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style slot-scoped>
.floatRight {
  float: right;
}
.headerSpan {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 5px 5px;
}
div.headerSpan {
  background: #777;
  width: 100%;
  margin-bottom: 10px;
}
h1, h2 {
  font-weight: normal;
}
.mobileSelect {
  display: none;
}
@media only screen and (max-width:768px){
 .deskSelect {
   display: none;
 }
 .mobileSelect {
   display: block;
 }
}
@media only screen and (max-width:1120px){
  .headerSpan {
    border-radius: 0;
  }
}
</style>
