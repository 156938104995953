<template>
  <div class="hello">
    <b-container>
      <b-container>
        <b-card class="my-2 border-dark" v-for="detail in parentBlogs" :key="detail.index" header-slot="header">
      <b-row slot="header">
        <b-col>
          <span v-show="!checkShowStatus(detail)" ><h4>{{ detail.checkdetailsTitle }}</h4><em>id:({{ detail.checkdetailsName }}) <span v-if="apparatusData" :title="getCheckCound(detail.checkdetailsName).map(p => p.apparatusName)"> used on {{ getCheckCound(detail.checkdetailsName).length }}</span></em></span>
          <b-form-input v-if="checkShowStatus(detail)" type="text" v-model="editValueTitle" />
          <b-form-input v-if="checkShowStatus(detail)" type="text" v-model="editValue" :disabled="apparatusData && getCheckCound(detail.checkdetailsName).length >= 0 ? true : false" />
        </b-col>  
        <b-col cols="3">
          <b-btn size="sm" variant="warning" v-show="!checkShowStatus(detail)" @click="showEditBox(detail)">Edit</b-btn>
          <b-btn @click="showEditBox(detail)" v-show="checkShowStatus(detail)" size="sm" variant="warning">Cancel</b-btn>
           <b-btn v-show="checkShowStatus(detail)" size="sm" variant="info" :class="{disabled: detail.checkdetailsName == editValue}" @click="updateDetail(detail)">Update</b-btn>
          <b-btn size="sm" variant="danger" v-if="userData.role == 'admin'" :class="{disabled: detail.checkdetailsDetails.length > 0}"  @click="deleteSub(detail)">Delete</b-btn>
        </b-col>
      </b-row>
        <b-alert variant="warning" :show="detail.checkdetailsDetails.length == 0">
         No Items will be Shown in Daily Vehicle Check
        </b-alert> 
        <b-btn block class="w-50 mx-auto" variant="warning" @click="showEditSub = !showEditSub">Edit List Items</b-btn>
        <b-card no-body size="sm" class="my-2 border-dark pt-2" v-for="(subblog, index) in returnArrayOfObjects(detail.checkdetailsDetails)" :key="index" >
          <b-row >
          <b-col>
          <h5  v-show="!showEditSub">{{ subblog.value }}</h5>
            <b-form-input class="ml-1 mb-1" v-if="showEditSub" type="text" v-model="subblog.value" />
        </b-col>  
        <b-col cols="3" v-show="showEditSub">
           <b-btn  size="sm" variant="info"  @click="updateList(subblog, index, detail)">Update</b-btn>
           <b-btn size="sm" variant="danger" @click="updateListdelete(subblog, index, detail)" >Delete</b-btn>
        </b-col>
      </b-row>
        
          



      </b-card>
      <b-card class="border-primary mx-auto w-75" header-slot="header">
        <h5><em>Add New Item to {{ detail.checkdetailsTitle }}</em></h5>
        <b-container>
        <b-row>
          <b-col>
        <b-form-input v-if="parentBlogs" :placeholder="'New ' + detail.checkdetailsTitle + ' List Item'" v-model="inputNames[detail.checkdetailsName]" />
          </b-col>
          <b-col cols="*">
        <b-btn size="sm" class="mt-1" variant="primary" @click="addNew(detail)">Add New</b-btn>
          </b-col>
          </b-row>
          </b-container>
      </b-card>



      </b-card>
      <b-card class="bg-primary">
      <b-card class="border-primary" header-slot="header">
        <h5><em>Start New List</em></h5>
        <b-container>
        <b-row>
          <b-col>
             <b-form-input :placeholder="'List Id (ie. engAndChassis) Needs to be unique'" v-model="newSubCatId" :class="taxData.map(p => p.checkdetailsName.toLowerCase()).indexOf(newSubCatId.toLowerCase()) >= 0 ? 'requirement-error' : ''"/>
          </b-col>
          <b-col>
        <b-form-input :placeholder="'Easy to Read Title (ie. Engine and Chassis'" v-model="newSubCatName" />
          </b-col>
          <b-col cols="*">
        <b-btn variant="primary" @click="addNew()" :disabled="taxData.map(p => p.checkdetailsName.toLowerCase()).indexOf(newSubCatId.toLowerCase()) >= 0 ? true : false">Add New</b-btn>
          </b-col>
          </b-row>
          </b-container>
      </b-card>
      </b-card>
      </b-container>
    </b-container>
  </div>


</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
export default {
  props: ['taxData'],
  name: 'hello',
  components: { 


  },
  data () {
    return {
    newSubCatId: '',
    newSubCatName: '',
    inputNames: {},
    editId: '',
    editData: false,
    editValue: '',
    editValueTitle: '',
    showEditSub: false,

    }
  },
  created(){
    
  },
  computed: {
      ...mapState([
        'userData',
        'getSessionData',
        'apparatusData'
      ]),
      parentBlogs(){
        var data = this.taxData
        for(var i in data){
          this.inputNames[data[i].checkdetailsName] = ''
        }
        return _.sortBy(data, ['checkdetailsName'])
      },
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin'){
            return false
          } else {
            return true
          }
      },
  },
  watch: {

},
  methods: {
    returnArrayOfObjects(data){
      var array = []
      for(var i = 0; i < data.length; i++){
        array.push({value: data[i]})
      }
      return array
    },
    getCheckCound(value){
      var rows = this.apparatusData.filter(p => p.apparatusCheckRequirements && p.apparatusCheckRequirements.indexOf(value) >= 0)
     // console.log(rows)
      return rows
    },
    showEditBox(detail){
   // console.log(detail)
      if(this.editId == ''){
        this.editId = detail.id
        this.editValue = detail.checkdetailsName
        this.editValueTitle = detail.checkdetailsTitle
      } else if (detail.id != this.editId){
        this.editId = detail.id
         this.editValue = detail.checkdetailsName
         this.editValueTitle = detail.checkdetailsTitle
      } else {
        this.editId = ''
         this.editValue = ''
         this.editValueTitle = ''
      }
    },
    checkShowStatus(detail){
        if(detail.id != this.editId){
          return false
        } else {
          return true
        }
      },
    filterSubBlogs(data){
      return this.taxData.filter( p => {
        if(p.checkdetailsParent != '' && p.checkdetailsParent != undefined && p.checkdetailsParent != null){
          return p.checkdetailsParent == data.checkdetailsName
        }
      })
    },
    addNew(parent){
    if(parent == undefined){
      var method = 'post'
      var url = '/api/v1/taxonomy/checkdetails/'
      var data = {
        'checkdetailsName': this.newSubCatId,
        'checkdetailsTitle': this.newSubCatName,
        'checkdetailsDetails': [],
      }
    } else {
      var method = 'patch'
      var url = '/api/v1/taxonomy/checkdetails/' + parent.id
      var array = parent.checkdetailsDetails
      array.push(this.inputNames[parent.checkdetailsName])
      var data = {
        'checkdetailsDetails': array,
      }
    }
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'checkdetails', headers: headers, expire: true}
    axios({
      method: method,
      url: url,
      data: data,
        headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
          this.newSubCatName = '',
          this.newSubCatId = ''
  
      })
    
    },
    updateListdelete(sublog, index, detail){
      var newArray = detail.checkdetailsDetails
      newArray.splice(index, 1)
      console.log(newArray)
      console.log(index)
      var result = confirm('Are You Sure You Want to Delete ' + detail.checkdetailsName + ' ?')
      if (result) {
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'checkdetails', headers: headers, expire: true}
    axios({
      method: 'patch',
      url: '/api/v1/taxonomy/checkdetails/' + detail.id,
      data: {
          'checkdetailsDetails': newArray
        },
      headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
  
      })
    } else {}
    },
    deleteSub(detail){
      var result = confirm('Are You Sure You Want to Delete ' + detail.checkdetailsName + ' ?')
      if (result) {
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'checkdetails', headers: headers, expire: true}
    axios({
      method: 'delete',
      url: '/api/v1/taxonomy/checkdetails/' + detail.id,
      headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
  
      })
    } else {}
    },
    updateDetail(detail){
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'checkdetails', headers: headers, expire: true}
      axios({
        method: 'patch',
        url: '/api/v1/taxonomy/checkdetails/' + detail.id,
        data: {
          'checkdetailsName': this.editValue,
          'checkdetailsTitle': this.checkdetailsTitle,
        },
        headers: payload.headers.headers,
      }).then(response => {
       
        console.log(response.data)
        this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
        this.editValue = ''
        this.editId = ''
        
      }) 
    },
    updateList(sublog, index, detail){
      var newArray = detail.checkdetailsDetails
      newArray[index] = sublog.value

     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'checkdetails', headers: headers, expire: true}
      axios({
        method: 'patch',
        url: '/api/v1/taxonomy/checkdetails/' + detail.id,
        data: {
          'checkdetailsName': detail.checkdetailsName,
          'checkdetailsTitle': detail.checkdetailsTitle,
          'checkdetailsDetails': newArray
        },
        headers: payload.headers.headers,
      }).then(response => {
       
        console.log(response.data)
        this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
        this.editValue = ''
        this.editId = ''
        
      }) 
    },
  
  
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.requirement-error{
  border: 1px solid red !important;
}
h1, h2 {
  font-weight: normal;
}

</style>
