<template>
<div id="homelanding">
  <b-navbar type="dark" variant="dark">
      <b-nav-form>
        
      <b-navbar-brand class="bootstrapTable">Asset:</b-navbar-brand>
        <b-form-input v-model="searchData" placeholder="Search Desc/Model/Id/Serial" />
        <b-navbar-brand class="bootstrapTable ml-2">Category:</b-navbar-brand>
        <b-form-select v-model="filterCategory" :options="filterCategoryOptions" />
        <b-navbar-brand class="bootstrapTable ml-2">Tag:</b-navbar-brand>
        <b-form-select v-model="filterTagType" :options="taxonomyTags" />
        <div style="width: 250px" class="ml-2">
          <multiselect 
                    v-model="filterDataBaseId" 
                    :disabled="filterTagType == null"
                    :options="getOptionsFromSelection(filterTagType)" 
                    :multiple="false" 
                    :close-on-select="true" 
                    :clear-on-select="true" 
                    :hide-selected="true" 
                    :preserve-search="true" 
                    placeholder="Please select an Options"
                    :label="filterTagType == 'stationsData' ? 'stationName' : filterTagType == 'deptContactData' ? 'lastName' : 'apparatusName'" 
                    track-by="id">
        <template slot="option" slot-scope="props" v-if="filterTagType == 'deptContactData'" >
                            <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                            <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                          </template>
      </multiselect>
        </div>

    
      </b-nav-form>
  </b-navbar>
  <b-navbar type="dark" variant="dark">
      <b-nav-form>
              
      <b-navbar-brand class="bootstrapTable">Price Filter:</b-navbar-brand>
      <b-form-input type="number" v-model="filterPrice" />
        <b-form-checkbox-group class="text-light ml-2" :options="assetStatusOptions" v-model="assetStatusFilter" />
        <download-excel
          v-if="fixDataExcel.length > 0 && (userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'logisticsops' || userData.role == 'shopops')"
          name="Current Asset List"
          :data="fixDataExcel" >
        <b-btn block variant="info">Download As Excel</b-btn>
          </download-excel>
      
           <b-btn class="ml-2" v-if="!addNew && editItemId == '' && (userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'logisticsops' || userData.role == 'shopops')" variant="primary" @click="addNew = true">Add New</b-btn>
      
      </b-nav-form>
    </b-navbar>
<b-container fluid class="px-0">
  <b-card class="my-2" v-if="addNew || editItemId != ''">
  <b-btn v-if="addNew || editItemId != ''" class="float-right" @click="addNew = false, resetObj()" variant="danger" size="sm">X</b-btn>
  <b-row v-if="addNew || editItemId != ''">
    <b-col v-for="(item, index) in itemsArray" :key="index" :lg="item.cols" class="mb-3">
      <h5>{{ item.name}}</h5>
      <b-form-input v-if="item.type == 'text'" type="text" v-model="holdObj[item.vmodel]" :placeholder="item.placeholder" />
      <b-form-input v-if="item.type == 'number'" type="text" v-model="holdObj[item.vmodel]"  />
      <b-form-input v-if="item.type == 'date'" type="date" v-model="holdObj[item.vmodel]" :placeholder="item.placeholder" />
      <b-form-select v-if="item.type == 'select'" v-model="holdObj[item.vmodel]" :options="getOptions(item.opts)" />
    <multiselect v-if="item.type == 'multiselect'" 
              v-model="holdObj[item.vmodel]" 
              :disabled="holdObj[item.disabled] == null || holdObj[item.disabled] == ''"
              :options="getOptionsFromSelection(holdObj[item.disabled])" 
              :multiple="false" 
              :close-on-select="true" 
              :clear-on-select="true" 
              :hide-selected="true" 
              :preserve-search="true" 
              placeholder="Please select an Options"
              :label="holdObj[item.disabled] == 'stationsData' ? 'stationName' : holdObj[item.disabled] == 'deptContactData' ? 'lastName' : 'apparatusName'" 
              track-by="id">
   <template slot="option" slot-scope="props" v-if="holdObj[item.disabled] == 'deptContactData'" >
                      <div class="option__title">{{ props.option.firstName }} {{ props.option.lastName }} #{{ props.option.employeeId }}</div>
                      <div class="option__body">{{ props.option.deptRank }} | {{ props.option.stationAssignment }} | {{ props.option.shiftAssignment }}</div>
                    </template>
</multiselect>
      </b-col>
        <b-col lg="2" class="mb-3 pt-4">
          <b-btn variant="danger" v-if="userData.role == 'admin'" @click="deleteInventory">Delete</b-btn>
          <b-btn variant="primary" @click="submitInventory">{{ editItemId == '' ? 'Submit' : 'Save' }}</b-btn>
          </b-col>
    </b-row>
    </b-card>
  </b-container>
  <b-table :fields="fields" :items="sortedlogisticAssetData">
    <template slot="assetTag" slot-scope="data">
      <span v-if="data.item.assetType && data.item.assetDbid">{{ getAssetTagData(data.item.assetType, data.item.assetDbid)}}</span>
      </template>
      <template slot="assetManufactureDate" slot-scope="data">
        {{ getDateFormat(data.item.assetManufactureDate, true) }}
      </template>
    <template slot="assetHistory" slot-scope="data">
      <b-btn variant="secondary" size="sm" @click="historyItemId = data.item.id, historyData = data.item, showModal = !showModal">History</b-btn>
    </template>
    <template slot="Edit" slot-scope="data">
      <b-btn variant="warning" size="sm" @click="editCurrentItem(data.item)">Edit</b-btn>
      </template>
    </b-table>
    <b-modal  v-model="showModal" size="lg" :title="historyData.assetDescription + ' (' + historyData.assetId + ')'">
      <b-btn v-if="!addNewNote && (userData.role == 'admin' || userData.role == 'adminchief' || userData.role == 'logisticsops' || userData.role == 'shopops')" @click="addNewNote = !addNewNote" block variant="primary" size="sm">Add New History Item</b-btn>
      <b-card v-if="addNewNote" title="New History Item">
      <b-container fluid>
      <b-radio-group v-model="newNoteType" :options="newNoteTypeOpts" />
      <b-row class="my-2">
        <b-col><b-form-input type="date" v-model="holdDate" /></b-col>
        <b-col><b-form-input type="time" v-model="holdTime" /></b-col>
      </b-row>
      <b-textarea v-model="newNoteText" v-if="newNoteType == 'inspection' || newNoteType == 'Info'" placeholder="History comments" />
      <b-form-input v-model="newNoteText" v-if="newNoteType == 'file'" placeholder="Name for the File" />
                        <div class="image-upload" v-if="newNoteType == 'file'">
                     <input :disabled="newNoteText == ''" id="direct-upload" type="file" class="pictureInputButton" @change="postDocumentFile($event)" />
                  </div>
                   <b-row v-if="filePosting == true"><b-col align="right" cols="auto" class="pt-1">
                     <span div class="spinner">
                      <div class="bounce1"></div>
                      <div class="bounce2"></div>
                      <div class="bounce3"></div>      
                   </span></b-col></b-row>
      <b-row class="mt-2">
      <b-col align="right">
      <b-btn  @click="saveNewNote(historyData)" variant="primary" size="sm">Save</b-btn>
      <b-btn @click="addNewNote = !addNewNote" variant="danger" size="sm">Cancel</b-btn>
      </b-col>
      </b-row>
      </b-container>
      </b-card>
      <b-container class="mt-3">
          <b-row  v-if="iframePdf">
          <b-col>
         <b-btn variant="danger" @click="iframePdf = ''" size="sm">Close File</b-btn>
         <b-btn  variant="info" :href="iframePdf" target="_blank" size="sm">Open in New Tab</b-btn>
         </b-col>
         </b-row>
         <b-row>
         <b-embed v-if="iframePdf" :src="iframePdf" />
        </b-row>
        <b-row><b-col cols="3">Date:</b-col><b-col align="left">History Info: </b-col></b-row>
        <b-row v-for="(item, index) in sortedHistory" 
               :key="index"  
               :class="item.type == 'assign' ? 'table-info' : item.type == 'Info' || item.type == 'inspection' || item.type == 'file' ? 'table-primary' : 'table-danger'"
               >
          <b-col cols="3">
            <p class="mb-0">{{ getDateFormat(item.date) }}</p>
            <p v-if="item.author" class="mb-0">{{ getAuthorInfo(item.author) }}</p>
            </b-col>
          <b-col v-if="item.type == 'assign'" align="left">
            <p class="mb-0" v-if="item.current"><span class="font-weight-bold">Assigned: </span>{{ getAssetTagData(item.current.type, item.current.id)}}</p>
            <p class="mb-0" v-if="item.previous"><span class="font-weight-bold">Previous: </span>{{ getAssetTagData(item.previous.type, item.previous.id)}}</p>
          </b-col>
          <b-col v-else-if="item.type == 'status'" align="left">
            <p class="mb-0" v-if="item.current"><span class="font-weight-bold">New Status: </span>{{ item.current }}</p>
            <p class="mb-0" v-if="item.previous"><span class="font-weight-bold">Previous: </span>{{ item.previous }}</p>
          </b-col>
           <b-col v-else-if="item.type == 'Info'" align="left">
            <p class="mb-0"><span class="font-weight-bold">Note: </span>{{ item.data }}</p>
          </b-col>
          <b-col v-else-if="item.type == 'inspection'" align="left">
            <p class="mb-0"><span class="font-weight-bold">Inpection: </span>{{ item.data }}</p>
          </b-col>
          <b-col v-else-if="item.type == 'file'" align="left">
            <p class="mb-0"><span class="font-weight-bold">File: </span><b-btn class="m-0" size="sm" variant="link" @click="viewFileIframe(item.data.href)">{{ item.data.fileName }}</b-btn></p>
          </b-col>
          <b-col v-else align="left"> 
            {{ item.type }}
            </b-col>
            </b-row>
        </b-container>
      </b-modal>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import axios from 'axios'
import moment from 'moment'
import Multiselect from "vue-multiselect";
import JsonExcel from 'vue-json-excel';
export default {
components: {
  'multiselect' : Multiselect,
    'download-excel' : JsonExcel
},
props: [],
data() {
    return {
      addNew: false,
      addNewNote: false,
      editItemId: '',
      newNoteType: 'Info',
      newNoteText: '',
      newNoteTypeOpts: ['Info', {text: 'New Inspection', value: 'inspection'}, {text: 'Upload File', value: 'file'}],
      taxonomyTags: [
        {text: 'User', value: 'deptContactData'},
        {text: 'Station', value: 'stationsData'},
        {text: 'Apparatus', value: 'apparatusData'},
        {text: 'None', value: null}
      ],
      assetStatusFilter: [
        'In Service',
        'In Reserve',
        'Out of Service'
      ],
      historyItemId: '',
      historyData: '',
      showModal: false,
      fields: [
        'assetDescription',
        'assetId',
        'assetSerialNumber',
        'assetModel',
        'assetTag',
        'assetCategory',
        {key: 'assetManufactureDate', label: 'Mf Date', sortable: true},
        {key: 'assetPrice', label: 'Price', sortable: true},
        'assetPrice',
        'assetHistory',
        'assetStatus',
      ],
      assetCategoryOpts: [
          'Apparatus Equipment',
          'SCBA Packs/Bottles',
          'Communications',
          'EMS Equipment',
          'Station Equipment',
          'Gear'
      ],
      assetStatusOptions: [
        'In Service',
        'In Reserve',
        'Out of Service',
        'Archived'
      ],
      itemsArray: [
        {name: 'Description', placeholder: 'Item Description', vmodel: 'assetDescription', type: 'text', cols: 5},
        {name: 'Asset Id', placeholder: 'Item Asset Id', vmodel: 'assetId', type: 'text', cols: 3},
        {name: 'Serial Number', placeholder: 'Serial Number', vmodel: 'assetSerialNumber', type: 'text', cols: 4},
        {name: 'Asset Category', vmodel: 'assetCategory', type: 'select', opts: 'assetCategoryOpts', cols: 3},
        {name: 'Model/SubType', placeholder: 'Model/SubType', vmodel: 'assetModel', type: 'text', cols: 3},
        {name: 'Manufacture Date', vmodel: 'assetManufactureDate', type: 'date', cols: 3},
        {name: 'Asset Price', vmodel: 'assetPrice', type: 'number', cols: 3},
        {name: 'Asset Tag Type', vmodel: 'assetType', type: 'select', opts: 'taxonomyTags', cols: 3},
        {name: 'Asset Tag', vmodel: 'assetDbid', type: 'multiselect', cols: 4, disabled: 'assetType'},
        {name: 'Asset Status', vmodel: 'assetStatus', type: 'select', opts: 'assetStatusOptions', cols: 3},
      ],
      holdDate: moment().format('YYYY-MM-DD'),
      holdTime: moment().format('HH:mm'),
      iframePdf: '',
      filesForPost: '',
      filePosting: false,
      holdObj: {
          assetDescription: '', 
          assetId: '',
          assetType: '',
          assetCategory: '', 
          assetManufactureDate: null, 
          assetSerialNumber: '',
          assetModel: '', 
          assetOosDate: null,
          assetLastInspection: null, 
          assetDbid: '',
          assetHistory: null,
          assetStatus: 'In Service',
          assetPrice: 0, 
          },
          searchData: '',
          filterCategory: false,
          filterPrice: null,
          filterTagType: null,
          filterDataBaseId: null,
}
},
created () {
  if(this.userData.role == 'admin' || this.userData.role == 'adminchief' || this.userData.role == 'logisticsops' || this.userData.role == 'shopops'){
    this.fields.push('Edit')
  }
},
beforeDestroy(){

},
watch: {

},
computed: {
  ...mapState([
    'deptContactData',
    'apparatusData',
    'stationsData',
    'siteconfig',
    'userData',
    'getSessionData',
    'logisticAssetData'
  ]),
  sortedHistory(){
    return _.orderBy(this.historyData.assetHistory, ['date'], ['desc'])
  },
  filterCategoryOptions(){
    var array = _.cloneDeep(this.logisticAssetData)
    var filter = [... new Set(array.map(p => p.assetCategory))]
    var hold = _.sortBy(filter.filter(p => {
      if(p){
        return p
      }
    }))
    hold.push({text: 'UNASSIGNED', value: null})
    hold.push({text: 'None', value: false})
    return hold
  },
  sortedlogisticAssetData(){
    var priceAdjust = this.filterPrice
    var array = _.cloneDeep(this.logisticAssetData).filter(p => {
          if(p.assetDescription && p.assetDescription.toLowerCase().match(this.searchData.toLowerCase())){
            return p
          }
          if(p.assetId && p.assetId.toLowerCase().match(this.searchData.toLowerCase())){
            return p
          }
          if(p.assetSerialNumber && p.assetSerialNumber.toLowerCase().match(this.searchData.toLowerCase())){
            return p
          }
          if(p.assetModel && p.assetModel.toLowerCase().match(this.searchData.toLowerCase())){
            return p
          }
    })
    if(this.filterCategory != false){
      array = array.filter(p => {
        return p.assetCategory == this.filterCategory
      })
    }
    if(this.filterTagType){
      array = array.filter(p => {
        return p.assetType == this.filterTagType
      })
    }
    if(this.filterDataBaseId && this.filterDataBaseId.id){
      array = array.filter(p => {
        return p.assetDbid == this.filterDataBaseId.id
      })
    }
    if(this.assetStatusFilter){
      array = array.filter(p => {
        var index = this.assetStatusFilter.indexOf(p.assetStatus)
        if(index >= 0){
          return p
        }
      })
    }
    if(priceAdjust){
     // console.log('price', priceAdjust)
      array = array.filter(p => {
        if(p.assetPrice && parseFloat(p.assetPrice) > parseFloat(priceAdjust)){
          console.log(p.assetPrice, priceAdjust)
          return p
        }
      })
    }
    return _.orderBy(array, ['assetDescription'], ['asc'])
  },
    fixDataExcel(){
          var array = _.cloneDeep(this.sortedlogisticAssetData)
          //console.log(array)
          var hold = []
          for(var i in array){
            var obj = { assetDescription: array[i].assetDescription, assetModel: array[i].assetModel, assetId: array[i].assetId,  assetSerialNumber: '"' + array[i].assetSerialNumber + '"', assetManufactureDate: array[i].assetManufactureDate, assetCategory: array[i].assetCategory, assigned: this.getAssetTagData(array[i].assetType, array[i].assetDbid), assetStatus: array[i].assetStatus, assetPrice: array[i].assetPrice}
            hold.push(obj)
          }
          return hold
  },
},
mounted(){

},
watch:{

},
methods: {
  resetObj(){
    this.editItemId = ''
    this.holdObj = {
          assetDescription: '', 
          assetId: '',
          assetType: '',
          assetCategory: '', 
          assetManufactureDate: null, 
          assetSerialNumber: '',
          assetModel: '', 
          assetOosDate: null,
          assetLastInspection: null, 
          assetDbid: '',
          assetHistory: [],
          assetStatus: 'In Service',
          assetPrice: 0, 
          } 
  },
  editCurrentItem(data){
    this.editItemId = data.id
    var obj = _.cloneDeep(data)
    delete obj.id
    if(obj.assetType && obj.assetDbid){
      obj.assetDbid = this.getAssetObj(obj.assetType, obj.assetDbid)
    }
    obj.assetManufactureDate = obj.assetManufactureDate ? moment(obj.assetManufactureDate).format('YYYY-MM-DD') : obj.assetManufactureDate
    this.holdObj = obj
    window.scrollTo(0,0);
  },
  getAssetObj(type, id){
    var obj = this[type].find(p => p.id == id)
    if(obj){
      return obj
    } else {
      return null
    }
  },
  getOptions(type){
    return this[type]
  },
  viewFileIframe(fileName){
       var first = fileName.charAt(0)
      if(first != '/'){
        var fileName = '/' + fileName
      }
       axios({
            method: 'get',
            url: '/api/v1/upload' + fileName,
            headers: {
             'Authorization': this.getSessionData,
              'Content-Type' : 'application/json',
              },
            }).then( response => {
              this.iframePdf = response.data.signedRequest
              this.showFile = true

       /*        a.setAttribute("href", response.data.signedRequest)
               a.setAttribute("target", "_blank")
               a.dispatchEvent(new MouseEvent("click", {'view': window, 'bubbles': true, 'cancelable': true})) */
            }) 
       },
  getAuthorInfo(id){
    if(id){
      var obj = this.deptContactData.find(p => p.id == id)
      if(obj){
        return obj.firstName + ' ' + obj.lastName
      } return ''
    } return ''
  },
  getDateFormat(data, boo){
    if(data){
      if(boo){
         return moment(data).format('MM/DD/YY')
      } return moment(data).format('MM/DD/YY HH:mm')
    } return 'No Date'
  },
  getOptionsFromSelection(data){
    if(data){
      console.log(data)
    return this[data]
    } else {
      return []
    }
  },
  getAssetTagData(type, id){
    if(type && id){
      var obj = this[type].find(p => p.id == id)
      if(obj){
        return type == 'stationsData' ? obj.stationName : type == 'deptContactData' ? obj.lastName + ' (' + obj.employeeId + ')' : obj.apparatusName     
      } else {
        return 'none'
      }
    } else {
      return 'none'
    }
  },
  postDocumentFile(e){
      this.filePosting = true
      var files = e.target.files || e.dataTransfer.files; 
      if(!files[0]) {
        return;
      }
      var data = new FormData();
      data.append('uploadFile', files[0])
          axios({
        method: 'post',
        url: '/api/v1/upload/files/documents/' + this.userData.lastName + this.userData.employeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
      this.filesForPost = response.data 

      this.$nextTick(function(){
        this.filePosting = false
      })
        });
    },
  checkForChanges(id, data, history){
    var tks = ['assetDbid', 'assetInspectionDate', 'assetStatus']
    var previous = this.logisticAssetData.find(p => p.id == id)
    if(history){
      var historyHold = history
    } else {
      var historyHold = []
    }
    if(previous && data){
      for(var i in tks){
        if(previous[tks[i]] && data[tks[i]] && previous[tks[i]] != data[tks[i]] && tks[i] == 'assetDbid'){
          historyHold.push({date: moment().toISOString(), 
                            type: 'assign', 
                            current: {type: data.assetType, id: data.assetDbid}, 
                            previous: {type: previous.assetType, id: previous.assetDbid},
                            author: this.userData.id
                            })
        }
        if(previous[tks[i]] && data[tks[i]] && previous[tks[i]] != data[tks[i]] && tks[i] == 'assetInspectionDate'){
          historyHold.push({date: moment().toISOString(), 
                            type: 'inspection', 
                            current:  data.assetInspectionDate,
                            previous: previous.assetInspectionDate,
                            author: this.userData.id
                            })
        }
        if(previous[tks[i]] && data[tks[i]] && previous[tks[i]] != data[tks[i]] && tks[i] == 'assetOosDate'){
          historyHold.push({date: moment().toISOString(), 
                            type: 'oos', 
                            current:  data.assetOosDate,
                            previous: previous.assetOosDate,
                            author: this.userData.id
                            })
        }
        if(previous[tks[i]] && data[tks[i]] && previous[tks[i]] != data[tks[i]] && tks[i] == 'assetStatus'){
          if(data.assetStatus == 'Out of Service'){
            data.assetOosDate = moment().toISOString()
          } else {
            data.assetOosDate = null
          }
          historyHold.push({date: moment().toISOString(), 
                            type: 'status', 
                            current:  data.assetStatus,
                            previous: previous.assetStatus,
                            author: this.userData.id
                            })
        }
      }
    }
    if(historyHold && historyHold.length > 0){
      return historyHold
    } else {
      return null
    }
  },
  saveNewNote(data){
    this.editItemId = data.id
    var obj = {date: moment(this.holdDate + ' ' + this.holdTime, 'YYYY-MM-DD HH:mm').toISOString(), author: this.userData.id, type: this.newNoteType, data: ''}
    if(this.newNoteType == 'inspection'){
      data.assetLastInspection = moment().toISOString()
      obj.data = this.newNoteText
    } else if (this.newNoteType == 'Info'){
      obj.data = this.newNoteText
    } else {
      obj.data = {fileName: this.newNoteText, href: this.filesForPost}
    }
    if(data.assetHistory){
      data.assetHistory.push(obj)
    } else {
      data.assetHistory = [obj]
    }
    this.holdObj = data
    this.submitInventory()
    this.$nextTick(function(){
      this.addNewNote = false
      this.newNoteType = 'Info'
      this.newNoteText = ''
    })
  },
  deleteInventory(){
      axios({ 
          method: 'delete',
          url:  '/api/v1/taxonomy/logisticasset/' + this.editItemId,
          headers: {
              'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
        }).then( response => { 
             var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
            var payload = { taxonomy: 'logisticasset', headers: headers, expire: true}
            this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
            this.editItemId = ''
            this.addNew = true
            this.holdObj = {
                assetDescription: '', 
                assetId: '',
                assetType: '',
                assetCategory: '', 
                assetManufactureDate: null, 
                assetSerialNumber: '',
                assetModel: '', 
                assetOosDate: null,
                assetLastInspection: null, 
                assetDbid: '',
                assetHistory: [],
                assetStatus: 'In Service',
                assetPrice: 0, 
                } 
        })
  },
  submitInventory(){
    var data = this.holdObj
    if(data.assetDbid){
      data.assetDbid = data.assetDbid.id
    }
    if(data.assetManufactureDate){
      data.assetManufactureDate = moment(data.assetManufactureDate, 'YYYY-MM-DD').toISOString()
    }
    if(this.editItemId){
      var method = 'patch'
      var url = '/api/v1/taxonomy/logisticasset/' + this.editItemId
      data.assetHistory = this.checkForChanges(this.editItemId, data, data.assetHistory)
    } else {
      var method = 'post'
      var url = '/api/v1/taxonomy/logisticasset/'
    }
    axios({ 
     method: method,
     url: url,
     data: data,
     headers: {
         'Authorization': this.getSessionData,
       'Content-Type' : 'application/json',
     }
   }).then( response => {
     console.log(response.data)
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'logisticasset', headers: headers, expire: true}
     this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
     this.editItemId = ''
     this.addNew = true
      this.holdObj = {
          assetDescription: '', 
          assetId: '',
          assetType: '',
          assetCategory: '', 
          assetManufactureDate: null, 
          assetSerialNumber: '',
          assetModel: '', 
          assetOosDate: null,
          assetLastInspection: null, 
          assetDbid: '',
          assetHistory: [],
          assetStatus: 'In Service',
          assetPrice: 0, 
          } 
   })
    },

  
    

},

}
</script>
<style>
 .rangeHighlight.fullBottle {
   background-color: green;
 }
 thead.displayNone {
  display: none !important;
}
td.itemNameClass {
  width: 400px;
}
</style>
<style scoped>

.drillDivTraining {
  padding-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
}
.trainingTrackHeader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    margin-top: 10px;
    border-radius: 5px 5px 0 0;
    border: 1px solid rgba(0,0,0,.125);
}
.headerSpanStaffing {
  font-size: 26px;
  font-weight: bold;
  background: #777;
  padding: 5px;
  color: #fff;
  text-shadow: 1px 2px #000;
  box-shadow: 1px 6px 10px rgba(0,0,0,.3);
  z-index: 200;
  border-radius: 5px 5px 0px 0px;
}
div.headerSpanStaffing {
  background: #777;
  width: 100%;

}
.day-cell-mobile {
  display: none;
}
#homelanding {
  margin-top: 20px;
}
.blog-img {
object-fit: cover;
float: left;
height: 80px;
width: 80px;
border-radius: 100%; }
.pictureHouse {
  padding-left: 15px;
}
.blogTitle {
  color: red;
}
.blogSubData {
  font-style: italic;
  color: #777;
}
.blogBody {
  clear: both;
}
.calendarClass {
  margin: 10px 2% 0 2%;
}
.trimmedBody {
  cursor: pointer;
  max-height: 88px;
overflow: hidden;
margin-bottom: 20px;
padding-bottom: 10px;
background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .01) 1%, #ebebeb 93%);
filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#ffffff", GradientType=0);

}
@media only screen and (max-width:1120px){
  .headerSpanStaffing {
    border-radius: 0;
  }
}

</style>
