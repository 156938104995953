<template>
<div class="userPostsPanelData">
  <div id="userDataHeader">
      <div v-if="userData != ''">
          <b-row>
            <b-col align="left"><h3>Hello, {{ userData.firstName }}! <img class="tutHelpIcon" src="/images/siteicons/help-icon-right-align.png" title="Help" height="24" width="24" @click="showTutVideo = !showTutVideo"></h3>
            </b-col>
            <b-col cols="5" align="right" v-if="!siteconfig.calendarHideFire">
              <b-btn class="verison-info" variant="link"  to="/portal/tutorials">
                  Tutorials/Wikis/Support
                <b-badge variant="light" v-if="false"><img class="tutHelpIcon" src="/images/siteicons/tutorials-icon.png" title="Help" height="24" width="24" @click="showTutVideo = !showTutVideo"></b-badge>
             
            </b-btn>
            </b-col></b-row>
               <tutdata v-if="showTutVideo" singlePost="userpanel" />
          <b-card no-body>
    <b-tabs ref="tabs" card>
        <b-tab title="SuperUser"  v-if="userData.superUser" active>
                  <b-form-select text-field="text" value-field="value" v-model="currentRole" :options="siteRoles">
                  </b-form-select>
                  <b-form-checkbox v-model="currentBlogAccess" :value="true" :unchecked-value="false">Blog?</b-form-checkbox>
                  <b-form-checkbox-group 
                  :id="'checkbox2_super' + userData.id + 'super'" 
                  v-model="currentAdminOptions" 
                  @change="updateCurrentAdminOptions($event)"
                  name="flavour1" :options="secondaryAdminOptionsFilter(userData.role)">
                  </b-form-checkbox-group>
        </b-tab>
        <b-tab title="Recent Posts" class="userPanelTab" :active="userData.superUser ? false : true" >
          <h6 v-if="!pageSet"> No Recent Posts</h6>
          <div v-if="filterUserPosts != '' || userData.role == 'admin'">
          <div class="postDivBody" v-for="post in filterUserPosts" :key="post.id"> 
            <postcell  v-if="pageSet" :post="post" :showPost="false" />
        </div>
          <b-pagination v-if="pageSet && false" data-clickout="stop" :hide-goto-end-buttons="true" @click.stop align="center" size="md" :total-rows="userPosts.length" v-model="currentPage" :per-page="5"></b-pagination>
          <b-container>
            <b-row><b-col align="center">
           <b-button-group>
                <b-button variant="outline-primary" :disabled="currentPage == 1" @click="currentPage > 1 ? currentPage = currentPage - 1 : ''">Prev</b-button>
                <b-button v-for="(btn, index) in buttonsForFilter" 
                          :key="index" 
                          :variant="btn.text == currentPage ? 'primary' : 'outline-primary'"
                          @click="btn.function == 'page' ? currentPage = btn.text : btn.function == 'move' ? currentPage = btn.value : ''"
                          >{{ btn.text }}</b-button>
                <b-button variant="outline-primary" :disabled="currentPage == Math.floor((userPosts.length / 5) + 1)" @click="currentPage < (userPosts.length / 5) ? currentPage = currentPage + 1 : ''">Next</b-button>
           </b-button-group>
           </b-col>
           </b-row>
           </b-container>
        </div>

      </b-tab>
      <b-tab title="Profile" class="profileTabClass">
        <b-card no-body
        class="profileCardClass"
        style="max-width: 20rem;"
        :img-src="postPicture"
        img-alt="Image"
        img-top>
      <b-card-body class="profileCardBody"><b-container v-if="userEdit">
        <b-row v-if="displayItem == 'Loading' || displayItem == 'Compressing'"><b-col align="right" cols="auto" class="pt-1">
        <span div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>      
        </span></b-col><b-col align="left"><h4><strong>{{ displayItem }}</strong></h4></b-col></b-row>
          <b-row><b-col align="center">
                <b-btn v-if="!photoOptions" @click="photoOptions = !photoOptions" size="sm" variant="primary">Change Photo</b-btn>
                <b-btn v-if="photoOptions" @click="photoOptionsCancel" size="sm" variant="danger">Cancel Photo</b-btn>
              </b-col>
            </b-row>
            <b-row v-if="photoOptions"><b-btn-group class="mx-auto" size="sm">
                <b-btn @click="toggleAddCustom">Add Custom</b-btn>
                <b-btn @click="toggleAddNoLogo">No Logo</b-btn>
              </b-btn-group>
            </b-row>
              <b-row v-if="addNoLogo"><b-col><h4>Click Save to remove photo</h4></b-col></b-row>
         
          <input v-if="addCustom" class="pictureInputButton" type="file" @change="postImage" />
        </b-container>
        <b-row ><b-col align="right">
        <b-btn v-if="userEdit" variant="danger" size="sm" @click.stop="userEdit = !userEdit">Cancel</b-btn>
        <b-btn v-if="!userEdit" size="sm" variant="primary" @click.stop="setUserEdit">Edit</b-btn>
          </b-col>
        </b-row>
          <b-row>
           <b-col  class="iconCol" v-if="!siteconfig.calendarHideFire">
              <img class="iconImage" src="/images/siteicons/user.png" title="Username">
            </b-col>
             <b-col  class="iconCol  pt-1"  v-if="siteconfig.calendarHideFire">
              <b-badge variant="primary">Name</b-badge>
            </b-col>
            <b-col>
              <h5 class="userDataText">{{ userData.firstName }} {{ userData.lastName }}</h5>
            </b-col>
          </b-row>
          <b-row v-show="checkForHide('employeeId')">
            <b-col  class="iconCol"  v-if="!siteconfig.calendarHideFire">
              <img class="iconImage" src="/images/siteicons/employee-id-icon.png" title="Employee Id">
            </b-col>
            <b-col  class="iconCol  pt-1"  v-if="siteconfig.calendarHideFire">
              <b-badge variant="primary">ID</b-badge>
            </b-col>
            <b-col>
              <h5 class="userDataText">{{ userData.employeeId }}</h5>
            </b-col>
          </b-row>
          <b-row  v-show="checkForHide('deptRank')">
             <b-col  class="iconCol"  v-if="!siteconfig.calendarHideFire">
              <img class="iconImage" src="/images/siteicons/employee-rank-icon.png" title="Rank">
            </b-col>
            <b-col  class="iconCol  pt-1"  v-if="siteconfig.calendarHideFire">
              <b-badge variant="primary">Type</b-badge>
            </b-col>
            <b-col>
              <h5 class="userDataText">{{ userData.deptRank }}</h5>
            </b-col>
          </b-row>
          <b-row v-show="checkForHide('deptSpecialties')">
             <b-col  class="iconCol"  v-if="!siteconfig.calendarHideFire">
              <img class="iconImage" src="/images/siteicons/employee-spe-icon.png" title="Specialties">
            </b-col>
            <b-col v-if="userData.deptSpecialties">
              <h5 class="userDataText"><span v-for="(spec, index) in userData.deptSpecialties" :key="spec.index">{{ spec }}<span v-if="index + 1 != (userData.deptSpecialties.length)">, </span> </span></h5>
            </b-col>
          </b-row>
          <b-row v-show="checkForHide('shiftAssignment')">
            <b-col  class="iconCol"  v-if="!siteconfig.calendarHideFire">
              <img class="iconImage" src="/images/siteicons/calendar.png" title="Username">
            </b-col>
            <b-col v-if="!userEdit">
              <h5 class="userDataText">{{ userData.shiftAssignment }}</h5>
            </b-col>
            <b-col v-if="userEdit">
            <b-form-select text-field="shiftrotationName" value-field="shiftrotationName" v-model="shiftAssignment" :options="shiftsData">
            </b-form-select></b-col>
          </b-row>
          <b-row  v-show="checkForHide('stationAssignment')">
            <b-col  class="iconCol"  v-if="!siteconfig.calendarHideFire">
              <img class="iconImage" src="/images/siteicons/station.png" title="Station">
            </b-col>
             <b-col  class="iconCol  pt-1"  v-if="siteconfig.calendarHideFire">
              <b-badge variant="primary">Dept</b-badge>
            </b-col>
            <b-col  v-if="!userEdit">
              <h5 class="userDataText">{{ userData.stationAssignment }}</h5>
            </b-col>
            <b-col v-if="userEdit">
              <multiselect v-model="stationSelectValue" :options="stationsData" :multiple="false" :close-on-select="true" :clear-on-select="true" :hide-selected="true" :preserve-search="true" placeholder="Please select a Station" label="stationName" track-by="stationName">
                <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.stationName }}</span><span class="custom__remove" @click="props.remove(props.option)">❌</span></span></template>
              </multiselect>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="iconCol"  v-if="!siteconfig.calendarHideFire">
              <img class="iconImage" src="/images/siteicons/call-user.png" title="Personal Mobile">
            </b-col>
            <b-col  class="iconCol  pt-1"  v-if="siteconfig.calendarHideFire">
              <b-badge variant="primary">Mobile</b-badge>
            </b-col>
            <b-col  v-if="!userEdit">
              <h5 class="userDataText">{{ userData.personalMobile }}</h5>
            </b-col>

            <b-col v-if="userEdit"><b-form-input type="tel" v-model="phoneNumber" placeholder="555-555-5555" /></b-col>
          </b-row>
          <b-row>
            <b-col v-if="!userEdit && siteconfig.smsBilling" align="center">
              <h6>SMS Notifications: <span :class="smsApproved ? 'text-success' : 'text-danger'" class="font-weight-bold">{{ smsApproved ? 'On' : 'Off' }}</span></h6>
            </b-col>
            <b-col v-if="userEdit && siteconfig.smsBilling" align="center" class="mt-1">
              <b-btn :disabled="phoneNumber == '' || phoneNumber == null" @click="smsApproved ? smsApproved = false : showSmsModal()" size="sm" :variant="smsApproved ? 'danger' : 'primary'">{{ smsApproved ? 'Opt-out SMS' : 'Opt-in SMS' }}</b-btn>
              </b-col>
              </b-row>
          <b-row v-if="userEdit"><b-col align="right">
            <b-btn v-if="displayItem != 'image'" variant="primary" @click="userEditSubmit">Save</b-btn>
          <b-btn v-if="displayItem == 'image'" variant="primary" @click="submitPicturePost">Save</b-btn></b-col></b-row>
        </b-card-body>
        </b-card>


      </b-tab>
      <b-tab v-if="userData.employeeId == 108025" title="Training">

        </b-tab>
            <b-tab v-if="siteconfig.trainingTracking" title="Credentials" @click="loadCredentials = true, refreshCredential = !refreshCredential">
              <userCredential v-if="loadCredentials"  :refresh="refreshCredential" />
        </b-tab>
  </b-tabs>
</b-card>
      <p>
        <b-btn class="verison-info float-left" variant="link" @click="clearCacheData">Refresh Cache Data</b-btn>
         <b-btn class="verison-info" v-if="userData.role == 'admin'" variant="link" @click="getDeletes">Get Deletes</b-btn>
        <b-btn class="verison-info float-right" variant="link" :disabled="true" :to="{ path: '/version-history' }">Version {{ verisonGet }}</b-btn></p>
      </div>

    </div>
  </div>
</template>
<script>
import postCell from './userpaneltab/userpanelPosts.vue'
import userCredential from './userpaneltab/userpanelCredential.vue'
import TutData from '../tracking/deptTutorialsdata.vue'
import Multiselect from 'vue-multiselect'
import { mapState } from 'vuex'
import { bus } from '../../main.js'
import moment from 'moment'
import ImageCompressor from 'image-compressor.js'
import axios from 'axios'
import _ from 'lodash'
import Papa from 'papaparse'
import dataShareVue from '../tracking/dataShare.vue';
export default {
  props:[],
components:{
    'tutdata' : TutData,
    'postcell': postCell,
    'multiselect' : Multiselect,
    userCredential,
},
  data () {
    return {
      showTutVideo: false,
      addCustom: false,
      addStation: false,
      addNoLogo: false,
      photoOptions: false,
      userPostData: [],
      currentPage: 1,
      filteredData: '',
      postData: '',
      pageSet: true,
      timeData: '13:55',
      userEdit: false,
      shiftAssignment: '',
      stationSelectValue: '',
      phoneNumber: '',
      displayItem: 'none',
      textPost: true,
      photoPost: false,
      smsApproved: false,
      loadCredentials: false,
      refreshCredential: false,
      currentRole: '',
      currentAdminOptions: [],
      currentBlogAccess: null,
      secondaryAdminOptions: [
        {text: 'Admin Narcs Cards (EMSOPS Only)', value: 'adminNarcs', current: ['emsops','admin']},
        {text: 'Admin Users (Admin)', value: 'adminUsers', current: ['adminchief','admin']},
        {text: 'Admin Preplans (Admin)', value: 'adminPreplans', current: ['adminchief','admin']},
        {text: 'Admin Forms (Admin)', value: 'adminForms', current: ['adminchief','fireops','trainingops','admin']},
        {text: 'Admin Training (Admin)', value: 'adminTraining', current: ['adminchief','trainingops','admin']},
        {text: 'Admin Staffing (Admin)', value: 'adminStaffing', current: ['adminchief','admin']},
        {text: 'Admin Maintenance (Admin, Shopops)', value: 'adminMaintenance', current: ['adminchief', 'shopops','admin']},
        {text: 'Admin Documents (Admin)', value: 'adminDocuments', current: ['adminchief','admin']},
        {text: 'Admin Blogs (Admin)', value: 'adminBlogs', current: ['adminchief','admin']},
        {text: 'Admin Taxonomy (Admin)', value: 'adminTaxonomy', current: ['adminchief','admin']}
      ],
      siteRoles: [
          { text: 'SuperUser', value: 'admin'},
          { text: 'Admin', value: 'adminchief'},
          { text: 'Fire Ops' , value: 'fireops'},
          { text: 'EMS Ops', value: 'emsops'},
          { text: 'Training Ops', value: 'trainingops'},
          { text: 'Shop Ops', value: 'shopops'},
          { text: 'Logistics Ops', value: 'logisticsops'},
          { text: 'Supervisor', value: 'supervisor'},
          { text: 'User', value: 'user'},
      ],
    }
  },
  created(){
    bus.$on('updateSMSApproval', this.updateApproval)
    if(this.userData.superAdmin){
      var user = this.userData
      this.currentRole = user.role
      this.currentBlogAccess = user.blogAccess
      this.currentAdminOptions = user.secondaryAdmin
    }
    this.currentRole = this.userData.role
  },
  watch: {
    currentRole(newData){
      this.$store.dispatch('SET_SUPER_USER_ROLE', {type: 'role', value: newData})
    },
    currentBlogAccess(newData){
      // console.log(newData)
      this.$store.dispatch('SET_SUPER_USER_ROLE', {type: 'blogAccess', value: newData})
    },

  },
  computed:{
    ...mapState([
    'getSessionData',
    'stationsData',
    'userData',
    'config',
    'userPosts',
    'shiftsData',
    'siteconfig'

    ]),
    verisonGet(){
      var date = document.documentElement.dataset.buildTimestampUtc
      if(this.userData.role == 'admin'){
        console.log('Build Date: ' + date)
      }
      var month = moment('2018-05-01', 'YYYY-MM-DD')
      var monthDif = moment(date).diff(month, 'month')
      return "1." +  monthDif + '.' +  moment(date).format('MM') 
    }, 
    postPicture(){
      if(this.displayItem == 'image'){
        var data = this.fileUpload
      } else {
      var data = this.userData.picture ? this.userData.picture : 'files/default_images/Blank_User_Big.png'
      }
      var first = data.charAt(0)
      if(first == '/'){
        var lead = data.replace('/', '')
      } else {
        var lead = data
      }
     return this.siteconfig.deptStore + lead
    },
    buttonsForFilter(){
      var currentPage = this.currentPage
      var start = 1
      var length = Math.floor((this.userPosts.length / 5) + 1)
      var array = []
      for(var i = 0; i < length; i++){
        if(array.length < 6 && length >= 5){
          if(i == 0 && currentPage > 1 && ((length - currentPage) > 5)){
            array.push({text: '...', function: 'move', value: 1})
          }
          if(array.length < 5){ 
          var value = currentPage + 4 <= length ? currentPage : length - 4
          array.push({text: value + Number.parseInt(i), function: 'page'})
          }
          if(i == array.length + 1 && ((length - currentPage) > 5)){
            array.push({text: '...', function: 'move', value: length})
          }
        } else if(array.length < 6 && array.length <= length) {
          array.push({text: start + Number.parseInt(i), function: 'page'})
        }
      } 
      //return []
      return array
    },
    filterUserPosts(){
      var userHold = this.userPosts.filter(p => {
          if(p.updatedAt){
            return p
          }
      })
      var userSort = _.orderBy(userHold, ['updatedAt'], ['desc'])
       var userSort = _.uniqBy(userSort, function(o){
        return o.id;
     });
     var noUpdate = this.userPosts.filter(p => {
        if(!p.updateAt){
          return p
        }
     })
     if(noUpdate.length > 0){
       for(var i in noUpdate){
         userSort.push(noUpdate[i])
       }
     }
      this.pageSet = false
      if(userSort.length > 0){
      var page = this.currentPage
      var firstPage = (page - 1) * 5
      var secondPage = page * 5
      var post = userSort.slice(firstPage, secondPage)
      this.$nextTick(function(){
        this.pageSet = true

      })

      return post
    }
    },
    },
    methods: {
      downloadDeletes(data){
       var title = ' Delete Export ' + moment().toISOString() + '.csv'
     //  cons
       var data = Papa.unparse(data, {header: true})
      // console.log(data)
       const blob = new Blob([data], { type: 'text/csv' })
      // console.log(data)
       const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", title);
        link.click();
      },
      secondaryAdminOptionsFilter(data){
      var options = _.cloneDeep(this.secondaryAdminOptions)
      return options.filter(p => {
        var index = p.current.indexOf(data)
        if(index == -1){
          return p
        }
      })
    },
    checkForHide(field){
      if(this.siteconfig.hideUserFields && field){
        var index = this.siteconfig.hideUserFields.indexOf(field)
        if(index >= 0){
          return false
        } return true
      } return true
    },
    updateCurrentAdminOptions(event){
      this.$store.dispatch('SET_SUPER_USER_ROLE', {type: 'secondaryAdmin', value: event})
    },
      showSmsModal(){
        this.$emit('showSMSModal')
      },
      clearCacheData(){
        this.$store.dispatch('DELETE_LOCAL_DATA')
        this.$nextTick(function(){
          window.location.reload(true)
          //window.location.href = window.location.href
        })
      },
      updateApproval(data){
        if(data){
          this.smsApproved = data
        }
      },
      siteUrlAdd(data){
        return 'https://firerescuedev.com' + data
      },
      photoOptionsCancel(){
          this.addStation = false
          this.addNoLogo = false
          this.addCustom = false
          this.textPost = true
          this.photoPost = false
          this.photoOptions = false
          this.fileUpload = false
          this.displayItem = 'none'
      },
      toggleAddCustom(){
        this.addStation = false
        this.addNoLogo = false
        this.addCustom = true
      },
      toggleAddStation(){
        this.addStation = true
        this.addNoLogo = false
        this.addCustom = false
      },
      toggleAddNoLogo(){
        this.fileUpload = 'files/default_images/Blank_User_Big.png'
        this.displayItem = 'image'
      },
      setUserEdit(){
        this.shiftAssignment = this.userData.shiftAssignment
        var stationData = this.stationsData.filter((p) => {
          return p.stationName.match(this.userData.stationAssignment)
        })
        this.stationSelectValue = stationData[0]
        this.phoneNumber = this.userData.personalMobile
        this.smsApproved = this.userData.smsApproved ? this.userData.smsApproved : false,
        this.userEdit = !this.userEdit
      },
    postImage(e){
      this.displayItem = 'Compressing'
      this.imageLoadReset = false
      this.loadingFile = true
      var files = e.target.files || e.dataTransfer.files; 
      this.carouselToggle = false
      if(!files[0]) {
        return;
      }
      var vm = this
      var successReturn = 
       new ImageCompressor(files[0], {
        quality: .6,
        success(result) {
         vm.postImageAxios(result)
        }
        })
      
     },
     postImageAxios(result){
        this.displayItem = 'Loading'
        var data = new FormData();
        data.append('uploadFile', result);
          axios({
        method: 'post',
        url: '/api/v1/upload/files/userphotos/' + this.userData.lastName + this.userData.employeeId + '/' + moment().format('YYYY-MM-DD'),
        data: data,
        headers: {
          'Authorization' : this.getSessionData,
          'Content-Type' : 'multipart/form-data',
        }
      }).then((response) => {
          this.fileUpload = response.data
          this.imageLoadReset = true
          this.displayItem = 'image'

        });
     },
       submitPicturePost(){
         var userID = this.userData.id
       axios({
        method: 'patch',
        url: '/api/v1/users/profile/' + userID,
        data: {
            "picture": this.fileUpload,
            "stationAssignment": this.stationSelectValue.stationName,
            "shiftAssignment": this.shiftAssignment,
            "personalMobile" : this.phoneNumber

          },
          headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
          }
        }).then( response => {
        var token = this.getSessionData
        var token = token.replace('bearer ', '')
        this.$nextTick(function(){
        this.$store.dispatch('LOAD_LOGIN_STATUS', token)
        this.userEdit = false
        this.fileUpload = ''
        this.displayItem = 'none'

        })
      })
      },
      getDeletes(){
       axios({
        method: 'get',
        url: '/api/v1/content/dailynarctrack/getdeletes/',
          headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
            
          }
      }).then( response => {
        console.log(response.data)
        this.downloadDeletes(response.data)
      })
      },
      userEditSubmit(){
        var userID = this.userData.id
       axios({
        method: 'patch',
        url: '/api/v1/users/profile/' + userID,
        data: {
            "stationAssignment": this.stationSelectValue.stationName,
            "shiftAssignment": this.shiftAssignment,
            "personalMobile" : this.phoneNumber,
            "smsApproved": this.smsApproved

          },
          headers: {
            'Authorization': this.getSessionData,
            'Content-Type' : 'application/json',
            
          }
      }).then( response => {
        var token = this.getSessionData
        var token = token.replace('bearer ', '')
        this.$nextTick(function(){
        this.$store.dispatch('LOAD_LOGIN_STATUS', token)
        this.userEdit = false

        })
      })
    }






},

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.postDivBody{
  margin-top: -20px;
}
.verison-info {
  color: #ccc;
  margin-bottom: 0;
}
.profileCardClass.card {
  margin: 0 auto 0;
  box-shadow: 1px 6px 10px rgba(0, 0, 0, 0.3)
}
.profileTabClass {
    background: url("/images/siteicons/profileBackgroundImage.jpg");
    background-size: cover;
    background-position: bottom center;
    padding: 20px;
    border-radius: 5px;
}
.profileCardBody.card-body {
  padding: 20px;
}
.userDataText {
  padding-top: 7px;
}
.iconCol.col {
  max-width: 50px;
}
.spanClass {
  text-align: center;
  width: 95%;
  background: #eee;
  padding-top: 5px;
  border-radius: 5px;
  margin: 0 auto 10px;
}
.spanClass h4 {
  margin: 0;
}
#bottomGradant{
  position: absolute;
bottom: 0;
left: 0;
width: 100%;
text-align: center;
margin: 0; padding: 30px 0;

/* "transparent" only works here because == rgba(0,0,0,0) */
background-image: linear-gradient(to bottom, transparent, black);
}
#filterHeading {
  font-weight: bold;
  background: grey;
  color: #fff;
  border-left: 0;
  border-right: 0;
  width: 80%;
  margin-left: 10%;
  padding: 5px;
}
a {
  text-decoration: none;
  color: red;
}
.userPostsPanelData {
  padding: 10px;
}
.userPanelTab {
  padding: 1.25rem;
}
img.iconImage {
  width: 36px;
  height: auto;
}
img.tutHelpIcon {
  width: 24px;
  height: 24px;
  border-radius: 0;
  border: 0;
}

#userDataHeader h2{
  margin-bottom: 10px;
}
#userDataHeader h3{
  margin-top: 10px;
}
#userDataHeader {

  padding-left: 10px;
}
#usersPosts {
  text-align: center;
  border-bottom: 1px solid;
}
#usersPosts h3{
  margin-bottom: 0;
}
#usersPosts h4{
  margin-top: 2px;
}
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
.image-upload > label {
  cursor: pointer;
}
.image-upload > input
{
    display: none;
}
@media screen and (max-width: 768px) {
.postDivBody{
  margin-top: 0;
}
}
@media only screen and (max-width: 576px) {
.userPanelTab {
  padding: 0;
}
}


</style>
