import { render, staticRenderFns } from "./deptranksTax.vue?vue&type=template&id=f438aa98"
import script from "./deptranksTax.vue?vue&type=script&lang=js"
export * from "./deptranksTax.vue?vue&type=script&lang=js"
import style0 from "./deptranksTax.vue?vue&type=style&index=0&id=f438aa98&prod&lang=css"
import style1 from "./deptranksTax.vue?vue&type=style&index=1&id=f438aa98&prod&slot-scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports