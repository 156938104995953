import { render, staticRenderFns } from "./logisticAsset.vue?vue&type=template&id=5550ae35&scoped=true"
import script from "./logisticAsset.vue?vue&type=script&lang=js"
export * from "./logisticAsset.vue?vue&type=script&lang=js"
import style0 from "./logisticAsset.vue?vue&type=style&index=0&id=5550ae35&prod&lang=css"
import style1 from "./logisticAsset.vue?vue&type=style&index=1&id=5550ae35&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5550ae35",
  null
  
)

export default component.exports