import { render, staticRenderFns } from "./documentViewer.vue?vue&type=template&id=1efed571"
import script from "./documentViewer.vue?vue&type=script&lang=js"
export * from "./documentViewer.vue?vue&type=script&lang=js"
import style0 from "./documentViewer.vue?vue&type=style&index=0&id=1efed571&prod&slot-scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports