import { render, staticRenderFns } from "./deptSpecialtiesTax.vue?vue&type=template&id=78311098"
import script from "./deptSpecialtiesTax.vue?vue&type=script&lang=js"
export * from "./deptSpecialtiesTax.vue?vue&type=script&lang=js"
import style0 from "./deptSpecialtiesTax.vue?vue&type=style&index=0&id=78311098&prod&lang=css"
import style1 from "./deptSpecialtiesTax.vue?vue&type=style&index=1&id=78311098&prod&slot-scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports