<template>
    <b-container fluid>
  <b-navbar type="dark" variant="dark">
    <b-nav-form>
      <b-input type="date" v-model="startDate" />
      <b-input type="date" v-model="endDate" />
      <b-btn variant="primary" @click="getPayrollModByDateByUser">Run Report</b-btn>
    </b-nav-form>
    <b-nav-form class="ml-auto">
      <b-btn :variant="printOn ? 'danger' : 'success'" @click="printOn = !printOn">Turn {{ printOn ? 'Off' : 'On '}} Print View</b-btn>
      <b-btn class="ml-3" v-if="printOn" variant="primary" @click="executePrintJS">Print</b-btn>
      <b-btn class="ml-3" variant="primary" @click="showFilter = !showFilter">Show Filter</b-btn>
    </b-nav-form>
    </b-navbar>
      <b-navbar v-if="showFilter">
        <b-form-input type="text" class="ml-2" v-model="nameFilter" placeholder="Last Names (seperate by a space)" />
        <b-form-select class="ml-2" v-model="filterByRank" multiple :options="ranksReturned" />
        <b-form-select class="ml-2" v-model="filterBySpecial" multiple :options="specialtiesReturned" />
        <b-form-select class="ml-2" v-model="filterModType" multiple :options="modTypesReturn" />
    </b-navbar>
    <div :id="'print-' + printId"  :class="printReportOn ? 'printReport' : ''">
         <b-container v-if="printOn" class="mb-4" fluid>
      <b-row><b-col cols="5">
      <img :src="departmentLogoForPrint" /></b-col>
      <b-col cols="7" class="pt-2">
        <h3>Daily Combined Report</h3>
        <b-row><b-col>
          <h4><span class="font-weight-bold">Duty Shift: </span><span>{{ currentShift }}</span></h4>
          </b-col>
          <b-col>
               <h4><span class="font-weight-bold">Date: </span><span>{{ getTodaysDateForPrint }}</span></h4>
            </b-col>
            <b-col>
                 <h4><span class="font-weight-bold">Time: </span><span>{{ getCurrentTimeForPrint }}</span></h4>
              </b-col>
              </b-row>
              <em>Completed by {{ userData.deptRank }} {{ userData.firstName }} {{ userData.lastName }}</em>
        </b-col>
        </b-row>
      </b-container>
    
    <b-container v-if="userModsReturned">
        <b-table :items="userModsReturned" :fields="['payrollModCode', 'Approved', 'Awaiting', 'Denied']">
            <template slot="payrollModCode" slot-scope="data">
                <span :class="'payroll-' + data.item.payrollCodeColor">{{ data.item.payrollCode }}</span>
            </template>
          </b-table>
    </b-container>
      {{ userModsReturned }}
      </div>
      </b-container>
</template>

<script>
import { bus } from '../../../main'
import { mapState } from 'vuex'
import axios from 'axios'
import Papa from 'papaparse'
import moment from 'moment'
import multiselect from 'vue-multiselect'
import userCard from '../../tracking/personnel/personnelDataFilter.vue'
import JsonExcel from 'vue-json-excel';
import Blob from 'blob'
import customJs from '../../../../customjs.js';
import html2canvas from 'html2canvas';
export default {
  components: {
    multiselect,
    userCard,
    'download-excel' : JsonExcel,
  },
  
  name: 'roster-admin',
  props:  ['payrollCodesData', 'userSelected', 'holdState'],
  data () {
    return {
      printOn: false,
      currentView: null,
      showFilter: false,
      departmentLogoForPrint: '',
      startDate: null,
      endDate: null,
      payrollReports: [],
      userMonthViewMods: [],
      printId: '',
      printReportOn: false,
    }
  },
  created(){
    this.startDate = moment().startOf('year').format('YYYY-MM-DD')
    this.endDate = moment().endOf('year').format('YYYY-MM-DD')
    this.printId = moment().unix()
    this.onLoadImage(this.siteconfig.staticHost + 'banners/' + this.siteconfig.deptUrlSub + '/banner.png')
  },
  computed: {
        ...mapState([
          'getSessionData',
          'apparatusData',
          'stationsData',
          'deptContactData',
          'userData',
          'siteconfig'
        ]),
        userModsReturned(){
          var modifications = this.userMonthViewMods
          var proll = this.payrollCodesData
          var rData = []
          for(var p in proll){
            var mApproved = 0
            var mAwaiting = 0
            var mDenied = 0
            var obj = proll[p]
            var mods = modifications.filter(pm => pm.payrollModCode ==  proll[p].id)
            obj['mods'] = mods
            for(var m in mods){
              if(mods[m].payrollModApproved){
                mApproved = mApproved + parseFloat(mods[m].payrollModHours)
              } else if (mods[m].payrollModApproved == null){
                mAwaiting = mAwaiting + parseFloat(mods[m].payrollModHours)
              } else {
                mDenied = mDenied + parseFloat(mods[m].payrollModHours)
              }
            }
            obj['Approved'] = mApproved
             obj['Awaiting'] = mAwaiting
              obj['Denied'] = mDenied
            rData.push(obj)
          }
          rData = rData.filter(p => p.mods.length > 0)
          console.log(rData)
          return rData

        }
       
  },
  mounted(){

  },
  watch: {
  selectedDateHold(newData){
    if(newData != this.selectedDate){
    this.selectedDate = this.selectedDateHold
    }
  }

  },
  methods: {
    onLoadImage(url){
      var vm = this
    //  console.log('NEW IMAGE')
    //  console.log('image loaded')
      var img = new Image()
      img.src = url;
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        vm.departmentLogoForPrint = dataURL // .replace(/^data:image\/(png|jpg);base64,/, "")
      };
    },
    executePrintJS(){
        console.log('Print')
      var printId = '#print-' + this.printId
           this.printReportOn = true
      html2canvas(document.querySelector(printId)).then(canvas => {
       var image = canvas.toDataURL("image/jpeg")
       return printJS({
              printable: image,
              type: 'image',
              //header: 'Shift Command Roster | ' + moment().format('MM/DD/YYYY - HH:mm') + ' | by ' + this.userData.deptRank + ' ' + this.userData.lastName ,
              style: 'img { width:1200px !important; }',
            })
   // 
    });
    this.$nextTick(function(){
        this.printReportOn = false
    })
  },
      getDateTime(data){
        if(data){
          return moment(data).format('MM/DD/YYYY HH:mm:ss')
        } return 'Null Date'
      },
      sortModifications(reg, mods, user, employeeId){
        var array = reg
        var modsA = []
        var adj = []
        for(var i in mods){
          var obj = {}
          obj = _.cloneDeep(mods[i])
          obj['payrollModCodeDb'] = mods[i].payrollModCode
          var payrollMod = this.payrollCodesData.find(p => p.id == mods[i].payrollModCode)
          var exchange = mods[i].payrollModExchangeUserId == user
          var exOn = ''
          if(exchange){
            payrollMod = this.payrollCodesData.find(p => p.payrollCode == 'EXON')
          }
          if (mods[i].payrollModType == 'exchange' && mods[i].payrollModExchangeUserId != user){
            payrollMod = this.payrollCodesData.find(p => p.payrollCode == 'EXOFF')
          }
          obj['startTime'] == mods[i].payrollModDatetime
          obj['endTime'] = moment(mods[i].payrollModDatetime).add(mods[i].payrollModHours, 'hours').toISOString()
          obj['payrollModColor'] = payrollMod && payrollMod.payrollCodeColor ? payrollMod.payrollCodeColor : 'default'
          obj['payrollModExport'] = payrollMod && payrollMod.payrollCodeExport ? payrollMod.payrollCodeExport : exOn && exOn.payrollCodeExport ? exOn.payrollCodeExport : 'No Export'
          obj['payrollModCode'] =  payrollMod && payrollMod.payrollCode ? payrollMod.payrollCode : exOn && exOn.payrollCode ? exOn.payrollCode : 'No Code'
          if(this.createVacancy.indexOf(mods[i].payrollModType) != -1 || obj.payrollModCode == 'EXOFF'){
            adj.push(obj)
          } else {
          modsA.push(obj)
          }
        }
        var fixed =  this.checkForUserMods(array, adj)
        for(var m in modsA){
          fixed.push(modsA[m])
        }
      //  console.log(fixed)
        return _.orderBy(fixed, ['payrollModDatetime'], ['asc'])
      },
          getPayrollModByDateByUser(){
    //  this.rosterReload = false
              var user = this.userSelected ? this.userSelected.id : this.userData.id
              var start = moment(this.startDate, "YYYY-MM-DD").toISOString()
              var end = moment(this.endDate, "YYYY-MM-DD").toISOString()
              axios({
            method: 'get',
            url: '/api/v1/staffing/payrollmod/usermonthview/' + start + '/' + end + '/' + user,
            headers: {
                    'Authorization': this.getSessionData,
                'Content-Type' : 'application/json',

            }
          }).then( response => {
            this.userMonthViewMods = response.data
          //  this.getPayrollModExchange()
            // this.rosterReload = true
          })

    },
   


  }



  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
.printReport {
  width: 1200px;
}
.payroll-black {
      background-color: #000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-blue {
      background-color: #007bff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-blue {
      background-color: #00438b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-Yellow {
      background-color: #ffeb4f;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-default {
      background-color: #e3e3e3;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-orange {
      background-color: #ffb039;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-orange {
      background-color: #ff7400;
    padding: 4px 5px 2px 5px;
    color: black;
    border-radius: 5px;
}
.payroll-bright-purple {
      background-color: #ab4fff;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-purple {
      background-color:#51009b;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-green {
      background-color: #0fb906;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-green {
    background-color: #0a7622;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-bright-red {
    background-color: #ff0000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.payroll-red {
    background-color: #b40000;
    padding: 4px 5px 2px 5px;
    color: white;
    border-radius: 5px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.bootstrapMobile {
  display: none;
}
.inlineBlock {
  display: inline-block;
}
h1, h2 {
  font-weight: normal;
}
@media screen and (max-width: 768px) {
  .bootstrapTable {
    display: none;
  }
  .bootstrapMobile {
    display: block;
  }
}
</style>
