<template>
  <div class="hello">
    <b-container>
      <b-container>
        <b-card class="my-2 border-dark" v-for="blog in parentBlogs" :key="blog.index" header-slot="header">
      <b-row slot="header">
        <b-col>
          <h4 v-show="!checkShowStatus(blog)" >{{ blog.blogtypeName }}</h4>
          <b-form-input v-if="checkShowStatus(blog)" type="text" v-model="editValue" />
        </b-col>  
        <b-col cols="3">
          <b-btn size="sm" variant="warning" v-show="!checkShowStatus(blog)" @click="showEditBox(blog)" >Edit</b-btn>
          <b-btn @click="showEditBox(blog)" v-show="checkShowStatus(blog)" size="sm" variant="warning">Cancel</b-btn>
           <b-btn v-show="checkShowStatus(blog)" size="sm" variant="info" :class="{disabled: blog.blogtypeName == editValue}" @click="updateBlog(blog)">Update</b-btn>
          <b-btn size="sm" variant="danger" v-if="userData.role == 'admin'" :class="{disabled: filterSubBlogs(blog).length > 0}"  @click="deleteSub(blog)">Delete</b-btn>
        </b-col>
      </b-row>
        <b-alert variant="warning" :show="filterSubBlogs(blog).length == 0">
          Users will be unable to submit blogs for this Parent Category if no childern are added
        </b-alert> 
        <b-card no-body size="sm" class="my-2 border-dark pt-2" v-for="subblog in filterSubBlogs(blog)" :key="subblog.index" >
          <b-row >
          <b-col>
          <h5  v-show="!checkShowStatus(subblog)">{{ subblog.blogtypeName }}</h5>
            <b-form-input v-if="checkShowStatus(subblog)" type="text" v-model="editValue" />
        </b-col>  
        <b-col cols="3">
          <b-btn @click="showEditBox(subblog)" v-show="!checkShowStatus(subblog)" size="sm" variant="warning">edit</b-btn>
           <b-btn @click="showEditBox(subblog)" v-show="checkShowStatus(subblog)" size="sm" variant="warning">Cancel</b-btn>
           <b-btn v-show="checkShowStatus(subblog)" size="sm" variant="info"  @click="updateBlog(subblog, blog.blogtypeName)">Update</b-btn>
           <b-btn size="sm" variant="danger" @click="deleteSub(subblog)" >Delete</b-btn>
        </b-col>
      </b-row>
        
          



      </b-card>
      <b-card class="border-primary mx-auto w-50" header-slot="header">
        <h5><em>Add New {{ blog.blogtypeName }} Sub Category</em></h5>
        <b-container>
        <b-row>
          <b-col>
        <b-form-input :placeholder="'New ' + blog.blogtypeName + ' Sub Category Name'" v-model="newSubCatName" />
          </b-col>
          <b-col cols="*">
        <b-btn size="sm" class="mt-1" variant="primary" @click="addNew(blog.blogtypeName)">Add New</b-btn>
          </b-col>
          </b-row>
          </b-container>
      </b-card>



      </b-card>
      <b-card class="bg-primary">
      <b-card class="border-primary" header-slot="header">
        <h5><em>Add New Blog Parent Category</em></h5>
        <b-container>
        <b-row>
          <b-col>
        <b-form-input :placeholder="'New Blog Parent Category Name'" v-model="newSubCatName" />
          </b-col>
          <b-col cols="*">
        <b-btn variant="primary" @click="addNew()">Add New</b-btn>
          </b-col>
          </b-row>
          </b-container>
      </b-card>
      </b-card>
      </b-container>
    </b-container>
  </div>


</template>

<script>
import { mapState } from 'vuex'
import { bus } from '../../../main'
import moment from 'moment'
import axios from 'axios'
export default {
  props: ['taxData'],
  name: 'hello',
  components: { 


  },
  data () {
    return {
    newSubCatName: '',
    editId: '',
    editData: false,
    editValue: ''

    }
  },
  created(){
    
  },
  computed: {
      ...mapState([
        'userData',
        'documentTags',
        'shiftsData',
        'eventTags',
        'blogType',
        'departmentRanks',
        'departmentSpecialties',
        'vehicleCheckDetails',
        'vehicleCheckItems',
        'workgroupData',
        'getSessionData'
      ]),
      parentBlogs(){
        return this.taxData.filter( p => {
          return p.blogtypeParent == '' || p.blogtypeParent == undefined || p.blogtypeParent == null
        })
      },
      testUserRights(){
        if(this.userData.role == 'adminchief'
          || this.userData.role == 'admin'){
            return false
          } else {
            return true
          }
      },

      /*

      How to filter against two arrays

      docArray(){
        var loaded = [... new Set(this.documentsApi.map(p => p.field_document_id))]
        var bulk = [... new Set(this.docArrayPre.map(p => p.field_document_id))]
        var test = this.docArrayPre
        console.log(loaded)
        console.log(bulk)
        for(var i = 0; i < loaded.length; i++){
          var index = bulk.indexOf(loaded[i])
          if(index > -1){
          test.splice(index, 1)}
        }
        return test

      } */
  },
  watch: {

},
  methods: {
    showEditBox(blog){
      if(this.editId == ''){
        this.editId = blog.id
        this.editValue = blog.blogtypeName
      } else if (blog.id != this.editId){
        this.editId = blog.id
         this.editValue = blog.blogtypeName
      } else {
        this.editId = ''
         this.editValue = ''
      }
      
    },
    checkShowStatus(blog){
        if(blog.id != this.editId){
          return false
        } else {
          return true
        }
      },
    filterSubBlogs(data){
      return this.taxData.filter( p => {
        if(p.blogtypeParent != '' && p.blogtypeParent != undefined && p.blogtypeParent != null){
          return p.blogtypeParent == data.blogtypeName
        }
      })
    },
    addNew(parent){
      console.log(parent)
    if(parent == undefined){
      var parent = ''}
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'blogtype', headers: headers, expire: true}
    axios({
      method: 'post',
      url: '/api/v1/taxonomy/blogtype/',
      data: {
        'blogtypeName': this.newSubCatName,
        'blogtypeParent': parent
      },
        headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
          this.newSubCatName = ''
  
      })
    
    },
    deleteSub(blog){
      var result = confirm('Are You Sure You Want to Delete ' + blog.blogtypeName + ' ?')
      if (result) {
      var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData } }
     var payload = { taxonomy: 'blogtype', headers: headers, expire: true}
    axios({
      method: 'delete',
      url: '/api/v1/taxonomy/blogtype/' + blog.id,
      headers: payload.headers.headers,
      }).then(response => {
      this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
  
      })
    } else {}
    },
    updateBlog(blog, parent){
      if(parent == undefined){
        parent = ''
        var setParent = blog.blogtypeName
        var childBlogs = this.taxData.filter( p => {
        if(p.blogtypeParent != '' && p.blogtypeParent != undefined && p.blogtypeParent != null){
          return p.blogtypeParent == blog.blogtypeName
        }
        })
      }
     var headers = { headers: { 'content-type': 'application/json', 'Authorization': this.getSessionData, expire: true } }
     var payload = { taxonomy: 'blogtype', headers: headers}
      axios({
        method: 'patch',
        url: '/api/v1/taxonomy/blogtype/' + blog.id,
        data: {
          'blogtypeName': this.editValue,
          'blogtypeParent': parent
        },
        headers: payload.headers.headers,
      }).then(response => {
        if(parent == ''){
          for(var i = 0; i < childBlogs.length;){
            var count = 1
            var counter = childBlogs.length
            axios({
              method: 'patch',
              url: '/api/v1/taxonomy/blogtype/' + childBlogs[i].id,
              data: {
                'blogtypeName': childBlogs[i].blogtypeName,
                'blogtypeParent': this.editValue
              },
               headers: payload.headers.headers,
             }).then(response => {
               count++
               if(count == counter)
              this.$nextTick(function(){
              this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
               this.editValue = ''
               this.editId = ''
                })
               })
               i++
       
          }    


        } else {
        console.log(response.data)
        this.$store.dispatch('LOAD_TAXONOMY_DATA_EXP', payload)
        this.editValue = ''
        this.editId = ''
        }
      })
    },
  
  
 },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.table th {
  text-align: center !important;
}
</style>
<style slot-scoped>
h1, h2 {
  font-weight: normal;
}

</style>
